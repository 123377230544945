<template>

    <div class="container page-container">

        <Spinner></Spinner>

        <h2 style="padding-top: 30px; text-align: center">Srovnání předpovědí</h2>

        <hr>

        <LocationAutocomplete class="centered" style="margin-left: 10px; margin-right: 10px; margin-top: 30px; margin-bottom: 25px"></LocationAutocomplete>

        <h3 class="location-title" style="margin-top: 35px; margin-bottom: 8px">
            {{ getPageTitle }}
            <span class="material-icons icon-rotate" :style="getRotateStyle" @click="reload()"
                  style="color: #086cd2; margin-bottom: 2px; margin-left: 2px; cursor: pointer">refresh
            </span>
        </h3>

        <h6 class="location-title" style="font-weight: 500;">
            <span v-if="getLatitude && getLongitude">
                {{ getLatitude.toFixed(2) }}°S {{ getLongitude.toFixed(2) }}°V
            </span>
            <a :href="getLightPollutionSiteLink()" @click.stop target="_blank" class="link rounded" style="font-size: 1.0em;" rel="noreferrer">
                SQM
            </a>
            <a :href="getMeteoblueSatelliteLink()" @click.stop target="_blank" class="link rounded" rel="noreferrer">
                <span class="material-symbols-outlined link-icon" style="font-size: 1.0em;">
                    satellite_alt
                </span>
            </a>
            <a v-if="getMapyCzLink()" :href="getMapyCzLink()" @click.stop target="_blank" class="link rounded" rel="noreferrer">
                <span class="material-icons  link-icon" style="font-size: 1.0em;">
                    location_on
                </span>
            </a>
        </h6>

        <div style="margin-bottom: 20px"></div>

        <ForecastTable :uuid=getForecastUuid()></ForecastTable>

        <RegionalForecastMap v-if="getRegionalForecastExists"></RegionalForecastMap>

        <ForecastTrend v-if="getForecastTrend"></ForecastTrend>

<!--        location check needed as intentionally night reliabilities come empty sometimes-->
        <NightReliability v-if="!isForeignLocationDisplayed"></NightReliability>

        <ForecastLongTermVerifications v-if="getDailyChart"></ForecastLongTermVerifications>

        <ForecastPastHourVerifications v-if="getPastHoursVerifications"></ForecastPastHourVerifications>

        <ForecastSearchLegend></ForecastSearchLegend>

        <NewsPopup></NewsPopup>

    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import LocationAutocomplete from "@/components/forecast/LocationAutocomplete";
import ForecastTable from "@/components/forecast/ForecastTable";
import Spinner from "@/components/reusables/Spinner";
import {ForecastColors} from "@/config/forecast-colors";
import ForecastSearchLegend from "@/components/forecast/ForecastSearchLegend";
import ForecastTrendChart from "@/components/forecast/ForecastTrendChart";
import ForecastTrendDescription from "@/components/forecast/ForecastTrendDescription";
import NightReliability from "@/components/forecast/NightReliability";
import ForecastTrend from "@/components/forecast/ForecastTrend";
import ForecastLongTermVerifications from "@/components/forecast/ForecastLongTermVerifications";
import {Params} from "@/router/params";
import RegionalForecastMap from "@/components/forecast/RegionalForecastMap.vue";
import {Utils} from "@/utils/Utils";
import ForecastPastHourVerifications from "@/components/forecast/ForecastPastHourVerifications.vue";
import NewsPopup from "@/components/forecast/NewsPopup.vue";

export default {
    name: "ForecastSearch",
    beforeMount() {
        this.preloadRealtimeSunImage();
    },
    mounted () {
        window.scrollTo(0, 0);
        console.log("ForecastSearch props = " + this.uuid);
        console.log("ForecastSearch Params.forecastUuid = " + Params.forecastUuid);
        this.reliabilityRefreshInterval = setInterval(() => {
            if (new Date().getMinutes() === 17) {
                console.log("Scheduled forecast reliabilities refresh ...")
                this.reloadPartialForecastsUpdate();
            }
        }, 60 * 1000);
    },

    beforeDestroy() {
        console.log("ForecastSearch: beforeDestroy")
        if (this.reliabilityRefreshInterval) {
            clearInterval(this.reliabilityRefreshInterval);
        }
    },
    data() {
        return {
            rotations: 0,
            reliabilityRefreshInterval: null
        }
    },
    computed: {
        ...mapGetters('forecastsStore', ['getPageTitle', 'getLatitude', 'getLongitude', 'getForecastTrend',
            'getRegionalForecastExists', 'getPastHoursVerifications', 'getNightReliabilityCharts', 'getDailyChart',
            'isForeignLocationDisplayed']),
        getRotateStyle() {
            return `transform: rotate(${this.rotations * 360}deg);`;
        },
    },
    methods: {
        ...mapActions('forecastsStore', ['reloadForecasts', 'reloadPartialForecastsUpdate']),
        ...mapActions('staticResourcesStore', ['preloadRealtimeSunImage']),
        reload() {
            this.rotations++;
            this.reloadForecasts();
        },
        getShadesOfReliability() {
            return  [...ForecastColors.shadesOfReliability].reverse();
        },
        getShadeStyle(shade) {
            return "text-align: center; backgroundColor: " + shade;
        },
        getForecastUuid() {
            return Params.forecastUuid;
        },
        getLightPollutionSiteLink() {
            if (this.getLatitude && this.getLongitude) {
                // zoom 9.2 shows lots of context but no name of the place
                return `https://www.lightpollutionmap.info/#zoom=12.8&lat=${this.getLatitude}&lon=${this.getLongitude}`;
            } else {
                let zoom = this.getZoom();
                return `https://www.lightpollutionmap.info/#zoom=${zoom}&lat=49.87&lon=15.46`;
            }
        },
        getMapyCzLink() {
            if (this.getLatitude && this.getLongitude) {
                // https://mapy.cz/turisticka?x=14.4418032&y=50.0554423&z=8
                return `https://mapy.cz/turisticka?x=${this.getLongitude}&y=${this.getLatitude}&z=14`;
            } else {
                return null;
            }
        },
        getMeteoblueSatelliteLink() {
            let zoom = this.getZoom();
            if (this.getLatitude && this.getLongitude) {
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps/index#coords=${zoom}/${this.getLatitude}/${this.getLongitude}&map=satellite~sat~none~none~none`;
            } else {
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps/index#coords=${zoom}/49.75/16.00/&map=satellite~sat~none~none~none`;
            }
        },
        getZoom() {
            const width = Utils.getScreenWidth();
            const zoom = width === 'small' ? 5.3 : width === 'medium' ? 6.0 : 6.78;
            return zoom;
        }
    },
    // eslint-disable-next-line vue/no-unused-components
    components: {
        NewsPopup,
        ForecastPastHourVerifications,
        RegionalForecastMap, ForecastSearchLegend, LocationAutocomplete,
        // eslint-disable-next-line vue/no-unused-components
        ForecastTable, Spinner, ForecastTrendChart, ForecastTrendDescription,
        NightReliability, ForecastTrend, ForecastLongTermVerifications}
}

</script>

<style scoped>

.icon-rotate {
    transition: transform .3s ease !important;
}

.link {
    background-color: rgb(245, 245, 245);
    margin-left: 8px;
    text-decoration: none;
    padding: 3px 10px;
}

.link-icon {
    vertical-align: middle;
    margin-bottom: 0.20em;
}

a:visited {
    color: #086cd2; /* Set the desired color for visited links */
    text-decoration: none;
}

</style>
