export class ForecastColors {

    static shadesOfBlue = [
        "#2692de",
        "#56aae3",
        "#7cb3e8",
        "#95c4ec",
        "#bfdbf8",
        "#c5dffa",
        "#d4e7fd",
        "#d4e7fd",
        "#eef7fc",
        "#fdffff"
    ]

    static shadesOfRed = [
        "#fae3e1",
        "#ffb3a1",
        "#fd6f4f",
        "#f6552e",
        "#d53003",
        "#d53004",
        "#d53005",
        "#d53006"
    ]

    static shadesOfGreen = [
        "#ecfcec",
        "#b8f6b4",
        "#58f356",
        "#13d90a",
        "#099b01",
        "#099b02",
        "#099b03",
        "#099b04"
    ]

    static shadesOfOrange = [
        "#faeee1",
        "#fce6ca",
        "#ffd0a1",
        "#fda86c",
        "#fd8c4f",
        "#f6812e",
        "#e76c1b",
        "#d56c04"
    ]

    static shadesOfReliability = [
        '#d70000', // Red (1)
        '#FF3300', // Dark Orange (2)
        '#fc8d44', // Orange (3)
        '#ffbe57', // Orange (4)
        '#fce500', // Yellow (5)
        '#fcfc2b', // Yellow (6)
        '#f6ffbf', // Lime Green (7)
        '#dbf59d', // Lime Green (8)
        '#7fe33b', // Lime Green (9)
        '#2bb409'  // Lime Green (10)
    ]

    static shadesOfReliabilitySign = [
        '#d9d8d8', // Red (1)
        '#e8e8e8', // Dark Orange (2)
        '#e8e8e8', // Orange (3)
        '#fafafa', // Orange (4)
        '#a1a1a1', // Yellow (5)
        '#a1a1a1', // Yellow (6)
        '#a1a1a1', // Lime Green (7)
        '#a1a1a1', // Lime Green (8)
        '#fafafa', // Lime Green (9)
        '#d9d8d8'  // Lime Green (10)
    ]

    static halfTransparentShadesOfReliability = [
        'rgba(215, 0, 0, 0.5)',    // Red (1) with 50% opacity
        'rgba(255, 51, 0, 0.5)',   // Dark Orange (2) with 50% opacity
        'rgba(252, 141, 68, 0.5)', // Orange (3) with 50% opacity
        'rgba(255, 190, 87, 0.5)', // Orange (4) with 50% opacity
        'rgba(252, 229, 0, 0.5)',  // Yellow (5) with 50% opacity
        'rgba(252, 252, 43, 0.5)', // Yellow (6) with 50% opacity
        'rgba(246, 255, 191, 0.5)',// Lime Green (7) with 50% opacity
        'rgba(219, 245, 157, 0.5)',// Lime Green (8) with 50% opacity
        'rgba(127, 227, 59, 0.5)', // Lime Green (9) with 50% opacity
        'rgba(43, 180, 9, 0.5)'    // Lime Green (10) with 50% opacity
    ];

    static noComparisonColor = "#dadada";

}
