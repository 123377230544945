<template>
    <div>

        <ForecastDays></ForecastDays>

        <ForecastSunMoon></ForecastSunMoon>

        <ForecastStatuses></ForecastStatuses>

        <h5 style="margin-bottom: 10px; text-align: center; font-size: 22px; font-weight: 600">
            {{getCloudTypeTitle()}}
        </h5>

        <ForecastCloudOptions></ForecastCloudOptions>

        <div ref="forecastTableContainer" class="table-responsive" style="overflow-x: scroll; margin-right: 10px">
            <table style="background-color: white">
                <thead>
                    <tr>
                        <th style="background-color: #8c8c8c; color: white; font-weight: bold; text-align: center; min-width: 100px; font-size: 11px">
                            Model \ Hodina
                        </th>
                        <th v-for="hour in hours" :key="hour" :style="currentHourStyle(hour)"
                            style="background-color: #413f3f; color: white; font-weight: normal; min-width: 25px;"
                            v-bind:ref="'hr-' + hour">
                            {{ hour }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sourceCode in getDisplayedSourcesIfDataExists()" v-bind:key="sourceCode">
                        <td nowrap>
<!--                            TODO do we really need to dupicate the following lines because of the check for forecast?-->
                            <div v-if="getSourceForecast(sourceCode, 0)">
                                <div class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                    <span style="margin-right: auto;" v-bind:style="getSourceStyle(sourceCode)">
                                        {{ getSourceForecast(sourceCode, 0).sourceTitle }}
                                    </span>
                                    <span v-if="getReliabilities" class="reliability" :style="getAccuracyStyle(sourceCode)">
                                        {{ getAccuracySign(sourceCode) }}
                                    </span>
                                </div>
                            </div>
                            <div v-else class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                <span style="margin-right: auto">{{ sourceDisplayName(sourceCode) }}</span>
                                <span v-if="getReliabilities" class="reliability" :style="getAccuracyStyle(sourceCode)">
                                    {{ getAccuracySign(sourceCode) }}
                                </span>
                            </div>
                        </td>
                        <td v-for="hour in hours" :key="hour" v-bind:style="getForecastStyle(hour, getHourForecast(hour, getSourceForecast(sourceCode, hour)))" class="cell">
                            <div v-if="getClouds(hour, getSourceForecast(sourceCode, hour)) !== null" class="cell-value">
                                {{ getClouds(hour, getSourceForecast(sourceCode, hour)) }}
                            </div>
                            <div v-else-if="getCloudIconNo(hour, getSourceForecast(sourceCode, hour)) !== null" class="cell-icon">
                                <img style="width: 22px; height: 23px"  alt="Clouds" :src="require('@/assets/icons/meteoblue/weather_pictograms/svg/'
                                    + getCloudIconNo(hour, getSourceForecast(sourceCode, hour)) + '.svg')"
                                />
                            </div>
                            <div v-else class="no-data">
                                -
                            </div>
                        </td>
                    </tr>
                    <tr v-if="forecastAvgMode !== 'NONE' && getDisplayedCloudType !== 'total_icon'" style="border-top: 2px solid #262626">
                        <td>
                            <div class="forecast-row-name source-name-parent d-flex align-items-end align-content-end">
                                Ø {{ getForecastAvgModeName() }}
                            </div>
                        </td>
                        <td v-for="hour in hours" :key="hour" v-bind:style="getForecastAvgStyle(hour)" class="cell" style="font-weight: bolder">
                            <div v-if="getCloudsAvg(hour) !== null" class="cell-value">
                                {{ getCloudsAvg(hour) }}
                            </div>
                            <div v-else class="no-data">
                                -
                            </div>
                        </td>
                    </tr>

                    <tr style="height: 20px"></tr>

                    <tr style="height: 28px">
                        <td>
                            <div class="forecast-row-name d-flex align-items-end align-content-end">
                                Den a noc
                            </div>
                        </td>
                        <td v-for="hour in hours" :id="'sun-' + hour" :key="hour" :style="getDayNightStyle(hour)"
                            v-on:click="toggleDisplayMoon" style="cursor: pointer">
                            <div class="parent-element">
                                <div v-if="showMoon(hour)" class="image-container" :style="getImageContainerClass(hour)" >
                                    <img class="image" :src="require('@/assets/images/' + getMoonImage(hour))" alt="Moon"
                                         style="z-index: 10;"/>
                                </div>
                                <div v-if="getDyHourIntervals(hour)" style="height: 28px">
                                    <Constellations :hour="hour" :moon-visible="showMoon(hour)"></Constellations>
                                </div>
                                <div v-else style="height: 28px; background-color: #f5f5f5">
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr style="border-top: none">
                        <td colspan="25" style="overflow: visible; position: relative; background-color: white; border-left: none; border-right: none; border-top: none">
                            <div class="overflowVisible">
                                <div class="stickyRowTitle">
                                    <img class="row-title-icon" :src="require('@/assets/icons/stars_icon.png')"/>
                                    Kvalita oblohy
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr v-for="detailField in getDetailFields" :key="detailField">
<!--                        TODO make an component of this?-->
                        <td v-if="isTitle(detailField)" colspan="25" style="overflow: visible; position: relative; background-color: white; border-left: none; border-right: none">
                            <div class="overflowVisible">
                                <div class="stickyRowTitle">
                                    <img class="row-title-icon" :src="require('@/assets/icons/' + getRowTitleIcon(detailField))"/>
                                    {{ getDetailName(detailField) }}
                                </div>
                            </div>
                        </td>
                        <td v-if="!isTitle(detailField)" nowrap>
                            <div class="forecast-row-name d-flex align-items-end align-content-end">
                                {{ getDetailName(detailField) }}
                            </div>
                        </td>
                        <td v-for="hour in hours" :key="hour" class="cell" style="height: 28px" :style="detailCellStyle(hour, detailField)">
                            <div v-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)
                                && detailField === 'seeing'"
                                 class="sky-quality-symbol-parent">
                                <SkyQuality mode="seeing" :value="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)"></SkyQuality>
                            </div>
                            <div v-else-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)
                                && detailField === 'transparency'"
                                 class="sky-quality-symbol-parent">
                                <SkyQuality mode="transparency" :value="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)"></SkyQuality>
                            </div>
                            <div v-else-if="getDetailedForecast(hour)
                                    && detailField === 'windAngle'
                                    && getWindArrowStyle(hour, getDetailedForecast(hour), detailField)"
                                 class="cell-value">
                                    <span class="material-icons" :style="getWindArrowStyle(hour, getDetailedForecast(hour), detailField)">
                                        trending_flat
                                    </span>
                            </div>
                            <div v-else>
                                <div v-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField) !== null"
                                     class="cell-value">
                                    {{ getHourForecastDetail(hour, getDetailedForecast(hour), detailField) }}
                                </div>
                                <div v-else class="no-data">
                                    -
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="isDisplayedWholeCountry" style="font-style: italic; margin-top: 5px; font-size: 13px">
            * Aktuálně zobrazované hodnoty jsou průměrem za celou ČR.
        </div>

        <div class="settings-button" @click="toggleSettingsPopup">
            <span class="material-icons" style="color: white">settings</span>
        </div>

        <div class="share-button" @click="toggleSharePopup">
            <span class="material-icons" style="color: white">share</span>
        </div>

        <div class="links-button" @click="toggleLinksPopup">
            <span class="material-icons" style="color: white">link</span>
        </div>

        <div class="overlay" v-if="settingsPopupDisplayed && !this.showSettingsOnWideScreen()" @click="toggleSettingsPopup"></div>
        <div class="overlay" v-if="sharePopupDisplayed" @click="toggleSharePopup"></div>
        <div class="overlay" v-if="linksPopupDisplayed" @click="toggleLinksPopup"></div>

<!--        TODO move this into its own component-->

        <div class="settings-popup" :class="showSettingsOnWideScreen() ? 'settings-popup-wide-screen' : 'settings-popup-small-screen'
"                v-if="settingsPopupDisplayed">

            <!--        TODO put to grid-->
            <!--        TODO the text display conditions are actually inverted, although the solution works its wrong ...-->
            <b-form-checkbox size="sm" id="forecastDisplayMode" v-model="centerOnMidnight" name="checkbox-1" value="true" unchecked-value="false" button button-variant="warning">
                <span class="material-icons" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">adjust</span>
                <span>{{ this.shouldCenterOnMidnight ? 'Vycentrovat poledne' : 'Vycentrovat půlnoc' }}</span>
            </b-form-checkbox>
            <b-form-checkbox v-if="!isForeignLocation" size="sm" id="forecastSortMode" v-model="sortByReliability" name="checkbox-1" value="true" unchecked-value="false" button button-variant="warning"
                             style="margin-top: 15px; width: 226px">
            <!--                fixed width needed for resizing-->
                <span class="material-icons" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">sort</span>
                <span>{{ this.shouldSortByReliability ? 'Řadit předpovědi abecedně' : 'Řadit předpovědi dle přesnosti'}}</span>
            </b-form-checkbox>

            <div style="margin-top: 15px">
                <b-dropdown size="sm" text="Ø Předpovědí oblačnosti" right variant="warning">
                    <b-dropdown-item-button :active="forecastAvgMode === 'ALL'" v-on:click="updateForecastAvgMode('ALL')">Všech zobrazených</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_3'" v-on:click="updateForecastAvgMode('BEST_3')">3 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_4'" v-on:click="updateForecastAvgMode('BEST_4')">4 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_5'" v-on:click="updateForecastAvgMode('BEST_5')">5 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'NONE'" v-on:click="updateForecastAvgMode('NONE')">Nezobrazovat</b-dropdown-item-button>
                </b-dropdown>
            </div>

            <DisplayedSourcesSettingsPopup style="margin-top: 15px"></DisplayedSourcesSettingsPopup>
            <ForecastSourceReliabilityIndicatorSettingsPopup v-if="!isForeignLocation" style="margin-top: 15px" @sourceReliabilityIndicatorSettingsPopupClosed="onReliabilityIndicatorSettingsPopupClosed"></ForecastSourceReliabilityIndicatorSettingsPopup>
            <WiderRegionSettingsPopup v-if="!isForeignLocation" style="margin-top: 15px" @widerRegionSettingsPopupClosed="onWiderRegionSettingsPopupClosed"></WiderRegionSettingsPopup>
            <NightSettingsPopup style="margin-top: 15px" @nightSettingsPopupClosed="onNightSettingsPopupClosed"></NightSettingsPopup>

            <div>
                <b-button v-if="!isDisplayedWholeCountry" size="sm" :variant="toggleHomeLocationButtonVariant()"
                          style="margin-top: 15px; text-align: left; white-space: nowrap;" @click="toggleHomeLocation">
                    <!--                fixed width needed for resizing-->
                    <span class="material-icons" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">home</span>
                    <span>{{ this.homeLocationDisplayed ? 'Smazat domovskou polohu' : 'Uložit polohu jako domovskou'}}</span>
                </b-button>
            </div>

        </div>

        <SharePopup v-if="sharePopupDisplayed"></SharePopup>
        <LinksPopup v-if="linksPopupDisplayed" @close-popup="toggleLinksPopup"></LinksPopup>

    </div>


</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BButton, BDropdown, BDropdownItemButton, BFormCheckbox, VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import {ForecastColors} from "@/config/forecast-colors";
import {Sources} from "@/config/sources";
import ForecastCloudOptions from "@/components/forecast/ForecastCloudOptions";
import Constellations from "@/components/forecast/Constellations";
import SkyQuality from "@/components/forecast/SkyQuality";
import ForecastDays from "@/components/forecast/ForecastDays";
import ForecastSunMoon from "@/components/forecast/ForecastSunMoon";
import NightSettingsPopup from "@/components/forecast/settings/NightSettingsPopup.vue";
import WiderRegionSettingsPopup from "@/components/forecast/settings/WiderRegionSettingsPopup.vue";
import ForecastSourceReliabilityIndicatorSettingsPopup
    from "@/components/forecast/settings/ForecastSourceReliabilityIndicatorSettingsPopup.vue";
import DisplayedSourcesSettingsPopup from "@/components/forecast/settings/DisplayedSourcesSettingsPopup.vue";
import SharePopup from "@/components/forecast/SharePopup.vue";
import {Utils} from "@/utils/Utils";
import LinksPopup from "@/components/forecast/LinksPopup.vue";
import ForecastStatuses from "@/components/forecast/ForecastStatuses.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'ForecastTable',
    mixins: [utilsMixin],
    props: {
        uuid: null
    },
    mounted() {
        console.log("Mounting ForecastTable component ... ");
        this.centerOnMidnight = this.shouldCenterOnMidnight;
        // console.log("this.centerOnMidnight = " + this.centerOnMidnight)
        this.sortByReliability = this.shouldSortByReliability;
        this.forecastAvgMode = this.getForecastAvgMode;
        if (this.uuid) {
            this.loadForecastsForUuid(this.uuid);
        } else {
            if (!this.getForecasts) {
                this.loadForecastsForWholeCountry();
            } else {
                this.scrollTable();
            }
        }
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getForecasts: function (newValue) {
            // console.log("Inside watch for getForecasts ...")
            this.scrollTable();
        },
        // eslint-disable-next-line no-unused-vars
        getForecastsLink: function (newValue) {
            console.log("Inside watch for getForecastsLink ...")
            this.sharePopupDisplayed = true;
        },
        centerOnMidnight: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.toggleCenterOnMidnight(newValue === 'true' || newValue === true);
                // this.scrollTable(); // TODO finish
            }
        },
        sortByReliability: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.toggleSortByReliability(newValue === 'true' || newValue === true);
            }
        },
        forecastAvgMode: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.changeForecastAvgMode(newValue);
            }
        },
    },
    data() {
        return {
            centerOnMidnight: undefined, // gets populated with text boolean actually! causes a lot of mess if not properly taken care of ...
            sortByReliability: undefined, // gets populated with text boolean actually! causes a lot of mess if not properly taken care of ...
            forecastAvgMode: 'ALL',
            minDiffForAccuracyStyling: 5,
            details: new Map([
                ['seeing', {display: '7timer seeing', divider: false}],
                ['transparency', {display: '7timer průzrač.', divider: false}],
                ['visibility', {display: 'Viditelnost (km)', divider: false, warningMax: 10, criticalMax: 6, greatMin: 30}],
                ['aerosol550', {display: 'Op. hl. aerosolů', divider: false, warningMin: 0.20, criticalMin: 0.5, greatMax: 0.1}],
                ['dust550', {display: ' ... z toho prach', divider: false, warningMin: 0.05, criticalMin: 0.15, greatMax: 0.001}],
                ['temperature-title', {display: 'Teplota', divider: true, icon: "temperature_icon.png"}],
                ['temperature', {display: 'Teplota (°C)', divider: false, warningMax: 0, criticalMax: -5}],
                ['feelsLike', {display: 'Pocitová (°C)', divider: false, warningMax: 0, criticalMax: -5}],
                ['wind-title', {display: 'Vítr', divider: true, icon: "wind_icon.png"}],
                ['windAngle', {display: 'Směr větru', divider: false}],
                ['windSpeed', {display: 'Vítr (m/s)', divider: false, warningMin: 3, criticalMin: 5, greatMax: 1.0}],
                ['windGusts', {display: 'Poryvy (m/s)', divider: false, warningMin: 5, criticalMin: 8, greatMax: 1.5}],
                ['humidity-title', {display: 'Vlhkost', divider: true, icon: "humidity_icon_2.png"}],
                ['dewPoint', {display: 'Rosný bod (°C)', divider: false}],
                ['relativeHumidity', {display: 'Rel. vlhkost (%)', divider: false, warningMin: 80, criticalMin: 90, greatMax: 60}],
                ['fog', {display: 'Mlha (%)', divider: false, warningMin: 5, criticalMin: 30}],
                ['precipitation-title', {display: 'Srážky', divider: true, icon: "rain_icon_1.png"}],
                ['precipitation', {display: 'Déšť (mm)', divider: false, warningMin: 0.001, criticalMin: 3}],
                ['showfall', {display: 'Sníh (cm)', divider: false, warningMin: 0.001, criticalMin: 3}],
                ['snowDepth', {display: 'Hl. sněhu (cm)', divider: false, warningMin: 1, criticalMin: 15}],
            ]),
            settingsPopupDisplayed: this.showSettingsOnWideScreen(),
            sharePopupDisplayed: false,
            linksPopupDisplayed: false,
            wideScreen: this.checkWideScreen()
        }
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', ['getSourceForecasts', 'getForecastDate',
            'todayForecastDisplayed', 'nextDayExists', 'getDayMovement',
            'getSourceForecast', 'getForecasts', 'getDetailedForecast', 'existsSourceForecast', 'getDisplayedCloudType',
            'getDyHourIntervals', 'getMoonHours', 'showMoon', 'getMoonImage',
            'shouldCenterOnMidnight', 'shouldSortByReliability', 'getReliabilities', 'getSourceReliability',
            'getSourceCodesByReliabilityDesc', 'getForecastAvgMode', 'getForecastsLink', 'getForecastTrend', 'isDisplayedWholeCountry',
            'isForeignLocation', 'getSmartSourcesToDisplay', 'homeLocationDisplayed', 'isDaytimeHour']),
        ...mapGetters('settingsStore', ['getDisplayedSources']),
        getDetailFields() {
            return Array.from(this.details.keys())
                .filter(k => {
                    const hideableRowsIfEmpty = ['seeing', 'transparency', 'aerosol550', 'dust550'];
                    if (hideableRowsIfEmpty.includes(k)) {
                        return this.existForecastDetail(k);
                    } else {
                        return k;
                    }
                })
        },
        hours() {
            if (this.shouldCenterOnMidnight) {
                const sequence1 = Array.from({ length: 12 }, (_, index) => index + 12);
                const sequence2 = Array.from({ length: 12 }, (_, index) => index);
                return sequence1.concat(sequence2);
            } else {
                return Array.from({ length: 24 }, (_, index) => index);
            }
        },
    },
    methods: {
        ...mapActions('forecastsStore', ['reloadForecasts',
            'loadForecastsForWholeCountry', 'toggleCenterOnMidnight', 'toggleSortByReliability', 'toggleDisplayMoon',
            'changeForecastAvgMode', 'fetchForecastsLink', 'loadForecastsForUuid', 'saveHomeLocation', 'deleteHomeLocation']),
        scrollTable() {
            // console.log("Inside scrollTable() ...");
            // insure we are leftmost -> so that we can then scroll to midnight ...
            this.$refs.forecastTableContainer.scrollBy({
                left: -2000,
            });

            const hour = 21;
            const targetHourEl = this.$refs['hr-' + hour][0];
            const targetLeft = targetHourEl.getBoundingClientRect().left;
            const width = document.documentElement.scrollWidth;
            // console.log("targetLeft = " + targetLeft + "; width = " + width);
            if (targetLeft + 200 > width) {
                const scrollBy = targetLeft - width + 200;
                // console.log("scrollBy = " + scrollBy)
                this.$refs.forecastTableContainer.scrollBy({
                    left: scrollBy,
                    behavior: 'smooth'
                });
            } else {
                // console.log("No scrolling ...")
            }
        },
        getDisplayedSourcesIfDataExists() {
            return this.getDisplayedSources.filter(s => this.existsSourceForecast(s, this.hours));
        },
        getImageContainerClass(hour) {
            const moonHours = this.getMoonHours(hour);
            let horizontal = 0;
            // eslint-disable-next-line no-unused-vars
            let vertical = 0;

            if (moonHours) {
                const hd = moonHours[hour];

                // some defaults
                horizontal = -50; // (-120 ... 20)
                vertical = 75;

                let horiz1 = {5: -120, 4: -85, 3: -50, 2: -15, 1: 20};
                let horiz2 = {5: 20, 4: -15, 3: -50, 2: -85, 1: -120};

                const parts = Math.floor(hd.minutesVisible / 12) + 1;
                if (hd.order === 1) {
                    horizontal = horiz1[parts];
                    // console.log("hd.minutesVisible = " + hd.minutesVisible + ", parts = " + parts)
                } else if (hd.order === hd.hoursCount) {
                    // console.log("hd.minutesVisible = " + hd.minutesVisible + ", parts = " + parts)
                    horizontal = horiz2[parts];
                }

                const min = 1;
                const maxMax = 110;
                const maxPossibleMoonAlt = 100; // lets assume that the upper border of the box is zenith
                let max;

                if (hd.maxAltitude) {
                    max = (hd.maxAltitude * (maxMax / maxPossibleMoonAlt));
                } else {
                    if (hd.hoursCount >= 14) {
                        max = 100;
                    } else if (hd.hoursCount >= 10) {
                        max = 80;
                    } else {
                        max = 60;
                    }
                }

                const even = hd.hoursCount % 2 === 0;

                // works for even numbers ...
                let count;
                let c;
                if (even) {
                    count = Math.floor(hd.hoursCount / 2 + 0.1);
                    c = count - 1;
                } else {
                    count = Math.ceil(hd.hoursCount / 2);
                    c = count - 1;
                }

                // calculation
                // a2 + b2 = c2
                // a=V c2 - b2

                let b = 0;

                if (even) {
                    if (hd.order < count) {
                        b = count - hd.order;
                    } else if (hd.order === count || hd.order === count + 1) { // top positions
                        b = 0;
                    } else {
                        // 6 - 4 - 1 -> b = 1
                        b = hd.order - count - 1;
                    }
                } else {
                    if (hd.order < count) {
                        // 5 - 1 -> b = 4
                        b = count - hd.order;
                    } else if (hd.order === count) {
                        b = 0;
                    } else {
                        // 6 - 5 -> b = 1
                        b = hd.order - count;
                    }
                }

                // console.log("date = " + hd.date + "; hoursCount = " + hd.hoursCount + "; order = " + hd.order + "; count = " + count + "; b = " + b+ "; c = " + c);

                let vertical = min + Math.floor(Math.pow(Math.pow(c, 2) - Math.pow(b, 2), 0.5) * max / count);

                // console.log("vertical = " + vertical);

                return `transform: translate(${horizontal}%, -${vertical}%);`;
            } else {
                return '';
            }
        },
        sourceDisplayName(sourceCode) {
            return Sources.displayName(sourceCode);
        },
        getDetailName(field) {
            return this.details.get(field).display;
        },
        getRowTitleIcon(field) {
            return this.details.get(field).icon;
        },
        isTitle(detailField) {
            return this.details.get(detailField).divider;
        },
        getMidnightBorderStyle(hour) {
            const border = document.documentElement.scrollWidth > 700 ? 2 : 1.5;
            return this.shouldCenterOnMidnight && hour === 23 ? `border-right: ${border}px solid #3f3f3f` : "";
        },
        detailCellStyle(hour, detailField) {
            if (this.isTitle(detailField)) {
                // cannot have conditional logic in v-for element generation so this is a workaround for this
                return "display: none";
            }
            const value = this.getHourForecastDetail(hour, this.getDetailedForecast(hour), detailField);
            const midnightBorderStyle = this.getMidnightBorderStyle(hour);
            const warningStyle = "background-color: rgb(252, 234, 192)" + "; " + midnightBorderStyle;
            const criticalStyle = "background-color: rgb(252, 212, 192)" + "; " + midnightBorderStyle;
            const greatStyle = "background-color: rgb(224, 247, 209)" + "; " + midnightBorderStyle;
            if ((value === 0 || value) && value !== '-') {
                const settings = this.details.get(detailField);
                if (detailField === 'dewPoint') {
                    // here value is the dewPoint value ...
                    const temperature = this.getHourForecastDetail(hour, this.getDetailedForecast(hour), 'temperature');
                    if (temperature - value <= 1) {
                        return criticalStyle;
                    } else if (temperature - value <= 2) {
                        return warningStyle;
                    }
                } else {
                    if (value >= settings.criticalMin) {
                        return criticalStyle;
                    } else if (value >= settings.warningMin) {
                        return warningStyle;
                    } else if (value <= settings.criticalMax) {
                        return criticalStyle;
                    } else if (value <= settings.warningMax) {
                        return warningStyle;
                    } else if (value <= settings.greatMax) {
                        return greatStyle;
                    } else if (value >= settings.greatMin) {
                        return greatStyle;
                    }
                }
            }
            return midnightBorderStyle;
        },
        getCloudValue(hourForecast) {
            const type = this.getDisplayedCloudType;
            if (type === 'high') {
                return hourForecast.cloudHigh;
            } else if (type === 'middle') {
                return hourForecast.cloudMiddle;
            } else if (type === 'low') {
                return hourForecast.cloudLow;
            } else if (type === 'total') {
                return hourForecast.cloudTotal;
            } else {
                return null;
            }
        },
        getForecastStyle(hour, hourForecast) {
            const midnightBorderStyle = this.getMidnightBorderStyle(hour);
            // TODO based on mode ...
            if (hourForecast) {
                const type = this.getDisplayedCloudType;
                if (type === 'total_icon') {
                    // return "background-color: rgb(101 172 244);" + midnightBorderStyle;
                    return "background-color: #5a93c4;" + midnightBorderStyle;
                } else {
                    return this.getStyle(this.getCloudValue(hourForecast)) + "; " + midnightBorderStyle;
                }
            }
            return midnightBorderStyle;
        },
        getSourceStyle(sourceCode) {
            let sourcesToDisplay = this.getSmartSourcesToDisplay;
            if (sourcesToDisplay) {
                if (Utils.isMe() && sourcesToDisplay.includes(sourceCode)) {
                    return "color: green";
                }
            }
            return "";
        },
        getForecastAvgStyle(hour) {
            const midnightBorderStyle = this.getMidnightBorderStyle(hour);
            const cloudsAvg = this.getCloudsAvg(hour);
            if (cloudsAvg || cloudsAvg === 0) {
                return this.getStyle(cloudsAvg) + "; " + midnightBorderStyle;
            }
            return midnightBorderStyle;
        },
        getStyle(cloud) {
            if (cloud || cloud === 0) {
                const clouds = cloud === 100 ? 99 : cloud;
                let background = ForecastColors.shadesOfBlue[Math.floor(clouds / 10)];
                return "backgroundColor: " + background
            }
            return "";
        },
        currentHourStyle(hour) {
            const midnightBorderStyle = this.getMidnightBorderStyle(hour);
            if (this.isDisplayedToday() && (!this.shouldCenterOnMidnight || (this.shouldCenterOnMidnight && hour >= 12))) {
                let current = new Date().getHours() === hour;
                if (current) {
                    return "background-color: yellow; text-align: center; color: black; " + midnightBorderStyle;
                }
            }
            return "text-align: center; " + midnightBorderStyle;
        },
        getHourForecast(hour, forecast) {
            if (forecast && forecast.hourlyForecasts) {
                return forecast.hourlyForecasts.find(h => h.hourInt === hour);
            }
            return null;
        },
        getClouds(hour, forecast) {
            if (forecast) {
                let hourForecast = this.getHourForecast(hour, forecast);
                if (hourForecast) {
                    const value = this.getCloudValue(hourForecast);
                    if (value !== null && value !== undefined) {
                        return value
                    }
                }
            }
            return null;
        },
        getCloudIconNo(hour, forecast) {
            const type = this.getDisplayedCloudType;
            if (forecast && type === 'total_icon') {
                let hourForecast = this.getHourForecast(hour, forecast);
                if (hourForecast) {
                    let suffix = '_night';
                    if (this.isDaytimeHour(hour)) {
                        suffix = '_day';
                    }
                    return hourForecast.iconNo + suffix;
                }
            }
            return null;
        },
        getCloudsAvg(hour) {
            let count = 0;
            let sum = 0;

            const limit = this.forecastAvgMode === 'ALL' ? this.getDisplayedSources.length
                : this.forecastAvgMode === 'BEST_3' ? 3
                    : this.forecastAvgMode === 'BEST_4' ? 4
                        : this.forecastAvgMode === 'BEST_5' ? 5
                            : 0;

            const codes = this.forecastAvgMode === 'ALL' ? this.getDisplayedSources
                : this.forecastAvgMode === 'BEST_3' ? this.getSourceCodesByReliabilityDesc
                    : this.forecastAvgMode === 'BEST_4' ? this.getSourceCodesByReliabilityDesc
                        : this.forecastAvgMode === 'BEST_5' ? this.getSourceCodesByReliabilityDesc
                            : [];

            let checkedCount = 0;

            for (const sourceCode of codes) {
                if (this.getDisplayedSources.includes(sourceCode)) {
                    checkedCount++;
                    const forecast = this.getSourceForecast(sourceCode, hour);
                    const clouds = this.getClouds(hour, forecast);
                    if (clouds || clouds === 0) {
                        count++;
                        sum += clouds;
                    }
                    if (checkedCount === limit) {
                        break;
                    }
                }
            }
            if (count > 0) {
                return Math.floor(sum / count);
            }
            return null;
        },
        getForecastAvgModeName() {
            if (this.forecastAvgMode === 'ALL') {
                return ' všech';
            }
            const included = this.forecastAvgMode === 'BEST_3' ? 3
                : this.forecastAvgMode === 'BEST_4' ? 4
                    : this.forecastAvgMode === 'BEST_5' ? 5 : 0;

            if (included > 0) {
                return " " + included + " nejpřes.";
            }
            return "";
        },
        getHourForecastDetail(hour, forecast, name) {
            if (forecast) {
                let hourly = this.getHourForecast(hour, forecast);
                if (hourly) {
                    const value = hourly[name];
                    if (value || value === 0) {
                        if ("aerosol550" === name || "dust550" === name || "precipitation" === name || "showfall" === name) {
                            let str = value.toString();
                            if (str.startsWith("0.") && value > 0) {
                                return str.slice(1);
                            }
                        }
                        return value;
                    }
                }
            }
            return null;
        },
        existForecastDetail(name) {
            for (let hour of this.hours) {
                let forecast = this.getDetailedForecast(hour);
                if (forecast) {
                    let hourly = this.getHourForecast(hour, forecast);
                    if (hourly) {
                        const value = hourly[name];
                        if (value || value === 0) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        getWindArrowStyle(hour, forecast, name) {
            const value = this.getHourForecastDetail(hour, forecast, name);
            if (value && value !== '-') {
                let degrees = 90 + value; // +90 to make the start position point down North -> South
                return `transform: rotate(${degrees}deg); font-size: 15px`;
            }
            return null;
        },
        isDisplayedToday() {
            return new Date(this.getForecastDate).getDate() === new Date().getDate();
        },
        getDayNightStyle(hour) {
            // const col = "background: linear-gradient(to right, #ffcc00 0%, #ffcc00 40%, #ff9900 50%, #ff6200 60%, #ff6200 100%);";
            const hourValues = this.getDyHourIntervals(hour);
            const midnightBorderStyle = this.getMidnightBorderStyle(hour);
            if (hourValues) {
                let colors = "";
                let sum = 0;
                // eslint-disable-next-line no-unused-vars
                for (const [idx, hourValue] of hourValues.entries()) {
                    if (hourValue.size > 0) {
                        let coef;
                        if (hourValue.size < 20) coef = 1.0;
                        if (hourValue.size >= 20) coef = 0.8;
                        if (hourValue.size >= 40) coef = 0.5;
                        if (hourValue.size >= 50) coef = 0.6;
                        if (hourValue.size >= 60) coef = 0.3;
                        if (hourValue.size === 100) coef = 1.0;

                        let hintSum = sum + hourValue.size * coef;
                        sum = sum + hourValue.size;
                        let color = "";

                        if (hourValue.type === 'EMPTY') color = '#ffffff';
                        if (hourValue.type === 'DAY') color = '#ffe971';
                        if (hourValue.type === 'CIVIL_TWILIGHT') color = '#ffc44c';
                        if (hourValue.type === 'NAUTICAL_TWILIGHT') color = '#0085cc';
                        if (hourValue.type === 'ASTRONOMICAL_TWILIGHT') color = '#012cb7';
                        if (hourValue.type === 'ASTRONOMICAL_NIGHT') color = '#011554';

                        let colorSection = `, ${color} ${hintSum}% ${sum}%`
                        // console.log(`idx = ${idx};hour = ${hour}; valueSize = ${hourValue.size}; colorSection = ${colorSection}`);
                        colors = colors + colorSection;
                    }
                }
                const background = `background: linear-gradient(to right${colors})`;
                // console.log(background); // TODO find out why this method is run whenever we hover over date arrows ...
                return background + "; " + midnightBorderStyle;
            }
            return midnightBorderStyle;
        },
        getAccuracyStyle(sourceCode) {
            let background;
            let fontStyle = "";
            const sourceReliability = this.getSourceReliability(sourceCode);
            if (sourceReliability
                && sourceReliability.reliability >= 0) {
                const idx = Math.floor(sourceReliability.reliability / 10);
                background = ForecastColors.shadesOfReliability[idx];
                fontStyle = "; font-weight: 600 ;color: " + ForecastColors.shadesOfReliabilitySign[idx];
            } else {
                background = ForecastColors.noComparisonColor;
            }
            const style = "background-color: " + background + fontStyle;
            return style;
        },
        getAccuracySign(sourceCode) {
            const sourceReliability = this.getSourceReliability(sourceCode);
            // TODO source the threshold from BE
            if (sourceReliability && Math.abs(sourceReliability.avgDiff) >= 10) {
                if (sourceReliability.avgDiff > 0) {
                    // more clouds then forecast
                    return "+";
                } else {
                    return "-";
                }
            }
            return "";
        },
        updateForecastAvgMode(mode) {
            this.forecastAvgMode = mode;
        },
        toggleSettingsPopup() {
            this.settingsPopupDisplayed = !this.settingsPopupDisplayed;
            this.hideSharePopup();
            this.hideLinksPopup();
        },
        hideSettingsPopup() {
            this.settingsPopupDisplayed = false;
        },
        toggleSharePopup() {
            if (this.sharePopupDisplayed) {
                this.sharePopupDisplayed = false;
            } else {
                this.fetchForecastsLink();
            }
            if (!this.showSettingsOnWideScreen()) {
                this.hideSettingsPopup();
            }
            this.hideLinksPopup();
        },
        hideSharePopup() {
            this.sharePopupDisplayed = false;
        },
        toggleLinksPopup() {
            this.linksPopupDisplayed = !this.linksPopupDisplayed;
            this.hideSharePopup();
            if (!this.showSettingsOnWideScreen()) {
                this.hideSettingsPopup();
            }
        },
        hideLinksPopup() {
            this.linksPopupDisplayed = false;
        },
        onNightSettingsPopupClosed() {
            // if (!this.checkWideScreen()) {
            //     this.hideSettingsPopup();
            // }
        },
        onWiderRegionSettingsPopupClosed() {
            // if (!this.checkWideScreen()) {
            //     this.hideSettingsPopup();
            // }
        },
        onReliabilityIndicatorSettingsPopupClosed() {
            // if (!this.checkWideScreen()) {
            //     this.hideSettingsPopup();
            // }
        },
        checkWideScreen() {
            return window.innerWidth > 768;
        },
        showSettingsOnWideScreen() {
            return window.innerWidth > 1500;
        },
        handleResize() {
            this.wideScreen = this.checkWideScreen();
        },
        toggleHomeLocation() {
            if (this.homeLocationDisplayed) {
                this.deleteHomeLocation();
            } else {
                this.saveHomeLocation();
            }
        },
        toggleHomeLocationButtonVariant() {
            if (this.homeLocationDisplayed) {
                return "danger";
            } else {
                return "success";
            }
        },
        getCloudTypeTitle() {
/*            if (this.getDisplayedCloudType === 'total_icon') {
                return "Celková oblačnost";
            } else if (this.getDisplayedCloudType === 'total') {
                return "Celková oblačnost v %";
            } else if (this.getDisplayedCloudType === 'high') {
                return "Vysoká oblačnost v %";
            } else if (this.getDisplayedCloudType === 'middle') {
                return "Střední oblačnost v %";
            } else if (this.getDisplayedCloudType === 'low') {
                return "Nízká oblačnost v %";
            }*/
            // return "";

            if (this.getDisplayedCloudType === 'total_icon') {
                return "Oblačnost";
            } else {
                return "Oblačnost v %";
            }
        },
    },
    components: {
        ForecastStatuses,
        LinksPopup,
        SharePopup,
        DisplayedSourcesSettingsPopup,
        ForecastSourceReliabilityIndicatorSettingsPopup,
        WiderRegionSettingsPopup,
        NightSettingsPopup,
        SkyQuality,
        Constellations,
        ForecastCloudOptions,
        ForecastDays,
        BButton,
        BFormCheckbox,
        BDropdown,
        BDropdownItemButton,
        ForecastSunMoon,
    }
}
</script>

<style scoped>

table {
    border-collapse: collapse;
    overflow-x: auto; /* enable horizontal scrolling */
    /*TODO with this enabled the programtic scrolling does not work*/
    /*display: block; !* important for sticky positioning *!*/
}

tr {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}

th:first-child,
td:first-child {
    position: sticky;
    left: 0;
    z-index: 1; /* make sure the sticky column is on top */
    /*background-color: #413f3f; !* set the background color to match the table *!*/
}

tr td:first-child {
    font-size: 11px;
    background-color: #f1eeee;
}

td, th {
    border: 1px solid #7e7c7c;
    width: 30px; /* Adjust the width as needed */
    text-align: center;
    font-size: 12px;
}

td.cell {

}

.cell-value {
}

.cell-icon {
    padding-top: 1.5px;
    padding-bottom: 2px;
}

@media (max-width: 699px) {
    .cell {
        font-size: 11px;
    }
    .cell-value {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media (min-width: 700px) {
    .cell {
        font-size: 11.5px;
    }
    .cell-value {
        padding-top: 5px;
        padding-bottom: 4.5px;
    }
}


th {
    font-weight: normal;
}

.parent-element {
    /* constellations depend on this*/
    position: relative; /* Set the parent element as a positioning context */
}

.image-container {
    position: absolute;
    top: 50%; /* Position the container at the vertical center of the parent element */
    left: 50%; /* Position the container at the horizontal center of the parent element */
    width: 38%;
}

.image {
    width: 100%; /* Set the width of the image to fill the container */
    height: auto; /* Automatically adjust the height to maintain the image's aspect ratio */
}

.source-name-parent {
    display: flex;
    align-items: center;
}

.sky-quality-symbol-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.settings-popup {
    position: fixed;
    bottom: 85px;
    right: 20px;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
}

.settings-popup-small-screen {
    background-color: rgba(245, 245, 245);
    border: 1px solid #ccc;
}

.settings-popup-wide-screen {
    background-color: rgba(245, 245, 245, 0.5);
}

.share-button {
    position: fixed;
    bottom: 20px;
    right: 76px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.links-button {
    position: fixed;
    bottom: 20px;
    right: 132px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.overflowVisible {
    overflow: visible;
}

.stickyRowTitle {
    max-width: calc(100vw - 26px);
    position: sticky;
    left: 0;
    bottom: 0;
    line-height: 1.66;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 4px;
    height: 20px;
}

.no-data {
    background-color: #f5f5f5;
    padding-top: 5px;
    padding-bottom: 5px;
}

.row-title-icon{
    height: 20px;
    margin-right: 4px;
    margin-bottom: 5px;
}

</style>

