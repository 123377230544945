<template>

    <div>
        <h3 class="location-title" style="margin-bottom: 20px">
            {{ getPageTitle }}
            <span v-if="getPageTitle && getPageTitle !== 'Česká republika'" style="margin-left: 0px">
                <b-button variant="light-outline" class="btn btn-sm shadow-none" v-on:click="unselectAllLocations"
                          style="margin-bottom: 2px">
                    ❌
                </b-button>
            </span>
        </h3>
        <hr class="short-hr">
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {BButton} from "bootstrap-vue";

export default {
    name: "LocationTitle",
    computed: {
        ...mapGetters('verificationsStore', ['getPageTitle'])
    },
    methods: {
        ...mapActions('verificationsStore', ['unselectAllLocations'])
    },
    components: {BButton}
}

</script>
