import {Sources} from "@/config/sources";
// import forecastsStore from "@/store/modules/forecasts-store";

const state = {
    displayedSources: getInitialDisplayedSources(),
    forbiddenSources: null,
}

function getInitialDisplayedSources() {
    console.log("Running ... initialisation ")
    const storedHiddenSources = localStorage.getItem('storedHiddenSources');
    if (storedHiddenSources) {
        const hidden = JSON.parse(storedHiddenSources);
        // TODO filter forbidden sources ...
        return Sources.getSourceCodes().filter(c => !hidden.includes(c));
    } else {
        return [...Sources.getSourceCodes()];
    }
}

const getters = {

    // eslint-disable-next-line no-unused-vars
    getDisplayedSources: (state, getters, rootState) => {
        const sortByReliability = rootState.forecastsStore.sortByReliability;
        const reliabilities = rootState.forecastsStore.reliabilities ? rootState.forecastsStore.reliabilities.values : null;

        if (sortByReliability && reliabilities) {
            const copy = [...state.displayedSources];
            return copy
                .filter(d => !getters.getForbiddenSources.includes(d))
                .sort(function (v1, v2) {
                    // eslint-disable-next-line no-prototype-builtins
                    const rel1 = reliabilities.hasOwnProperty(v1) ? reliabilities[v1] : null;
                    // eslint-disable-next-line no-prototype-builtins
                    const rel2 = reliabilities.hasOwnProperty(v2) ? reliabilities[v2] : null;
                    if (rel1 !== null && rel1 !== undefined && rel2 !== null && rel2 !== null) {
                        return rel2.reliability - rel1.reliability;
                    } else if (rel1 !== null && rel1 !== undefined ) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
        } else {
            return state.displayedSources;
        }
    },

    getDisplayedSourcesAlphabetically: (state, getters) => {
        let forbiddenSources1 = getters.getForbiddenSources;
        return state.displayedSources
            .filter(d => !forbiddenSources1.includes(d));
    },

    // isDisplayedSource: (state, getters) => (sourceCode) => {
    //     return getters.getDisplayedSources.includes(sourceCode);
    // }

    getForbiddenSources() {
        console.log("state.forbiddenSources = " + state.forbiddenSources);
        if (state.forbiddenSources) {
            return state.forbiddenSources;
        } else {
            return [];
        }
    },

    getSourceCodes: (state, getters) => {
        return Sources.getSourceCodes()
            .filter(s => !getters.getForbiddenSources.includes(s));
    },


}


const mutations = {

    addDisplayedSource: (state, value) => {
        if (!state.displayedSources.includes(value)) {
            const copy = [...state.displayedSources];
            copy.push(value);
            const orderedSources = Sources.getSourceCodes()
                .filter(s => copy.includes(s));
            state.displayedSources = orderedSources;
        }
    },

    removeDisplayedSource: (state, value) => {
        const index = state.displayedSources.indexOf(value);
        if (index > -1) {
            state.displayedSources.splice(index, 1);
        }
    },

    updateStore: (state) => {
        const hiddenSources = Sources.getSourceCodes().filter(s => !state.displayedSources.includes(s));
        localStorage.setItem('storedHiddenSources', JSON.stringify(hiddenSources))
    },

    setForbiddenSources: (state, value) => {
        state.forbiddenSources = value;
    }
}

const actions = {

    updateDisplayedSources({ commit }, forbiddenSources ) {
        // console.log("Received forbiddenSources = " + forbiddenSources)
        commit('setForbiddenSources', forbiddenSources);
    },

    unhideSource({commit, dispatch}, {sourceCode, reload}) {
        commit('addDisplayedSource', sourceCode);
        commit('updateStore');
        if (reload) {
            dispatch('forecastsStore/reloadForecasts', {}, {root: true})
        }
    },

    hideSource({commit, dispatch}, {sourceCode, reload}) {
        commit('removeDisplayedSource', sourceCode);
        commit('updateStore');
        if (reload) {
            dispatch('forecastsStore/reloadForecasts', {}, {root: true})
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
