<template>

    <div>
        <h6 style="margin-top: 20px; margin-bottom: 10px">Škála přesnosti předpovědí</h6>

        <div>
                <span class="reliability reliability-card"
                      v-for="shade in getShadesOfReliability()" v-bind:key="shade" :style=getShadeStyle(shade)></span>
        </div>

        <div style="margin-top: 6px">
                <span class="reliability reliability-card"
                      :style=getShadeStyle(getShadesOfReliability()[5])>
                    +
                </span> Více oblačnosti než předpovídáno
        </div>
        <div style="margin-top: 6px; " >
                <span class="reliability reliability-card"
                      :style=getShadeStyle(getShadesOfReliability()[5])>
                    -
                </span>
            Méně oblačnosti než předpovídáno
        </div>
        <div style="margin-top: 6px">
                <span class="reliability reliability-card"
                      :style=getShadeStyle(getShadesOfReliability()[5])>
<!--                    break just so that text desc is aligned-->
                    <br>
                </span>
            Směr chyby předpovědi není jednoznačný
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import LocationAutocomplete from "@/components/forecast/LocationAutocomplete";
import ForecastTable from "@/components/forecast/ForecastTable";
import Spinner from "@/components/reusables/Spinner";
import {ForecastColors} from "@/config/forecast-colors";
import SkyQuality from "@/components/forecast/SkyQuality";

export default {
    name: "ForecastReliabilityScaleLegend",
    computed: {
        ...mapGetters('forecastsStore', []),
    },
    methods: {
        ...mapActions('forecastsStore', []),
        getShadesOfReliability() {
            return [...ForecastColors.shadesOfReliability].reverse();
        },
        getShadeStyle(shade) {
            return "text-align: center; backgroundColor: " + shade;
        },
    },
    // eslint-disable-next-line vue/no-unused-components
    components: {SkyQuality, LocationAutocomplete, ForecastTable, Spinner}
}

</script>

<style scoped>

.reliability-card {
    display: inline-block;
    height: 20px;
    width: 14px;
    margin-right: 2px;
    text-align: center;
    line-height: 17px; /* Adjust line-height to match the height */
}


</style>
