<template>

    <div>
        <div class="constellation">
            <span v-for="(dot, idx) in getDots" :key="idx" class="dot" :style="getDotStyle(dot)"></span>
        </div>
    </div>

</template>

<script>


import {mapGetters} from "vuex";

export default {
    name: "Constellations",
    props: {
        hour: null,
        moonVisible: {
            type: Boolean,
            default: true // TODO to false ...
        },
    },
    created() {
        setInterval(() => {
            const randomColor = this.colors[Math.floor(Math.random() * this.colors.length)];
            this.backgroundColor = randomColor;
        }, 100); // Change color every 2 seconds (2000 milliseconds)
    },
    data() {
        return {
            backgroundColor: '',
            colors: [
                '#e8d3d3',
                '#edf6fa',
                '#cce4ee',
                '#badbe5',
                'rgb(248,238,223)',
                'rgb(239,227,209)',
                'rgb(241,229,208)',
                'rgb(239,227,210)'
            ],
            // keys are month and hour
            displayConstellation: {
                    0: { // January
                        1: {
                            17: "pegasus",
                            18: "pisces",
                            19: "triangulum",
                            20: "perseus",
                            21: "taurus",
                            22: "orion",
                            23: "canisMayor",
                            0: "gemini",
                            1: "cancer",
                            2: "hydra",
                            3: "leo",
                            4: "crater",
                            5: "ursaMayor",
                            6: "virgo"
                        },
                        2: {
                            17: "pisces",
                            18: "triangulum",
                            19: "perseus",
                            20: "eridanus",
                            21: "orion",
                            22: "canisMayor",
                            23: "gemini",
                            0: "cancer",
                            1: "hydra",
                            2: "leo",
                            3: "crater",
                            4: "ursaMayor",
                            5: "virgo",
                            6: "bootes"
                        }
                    },
                    1: { // Fabruary
                        1: {
                            17: "triangulum",
                            18: "perseus",
                            19: "taurus",
                            20: "orion",
                            21: "canisMayor",
                            22: "gemini",
                            23: "cancer",
                            0: "hydra",
                            1: "leo",
                            2: "crater",
                            3: "ursaMayor",
                            4: "virgo",
                            5: "bootes",
                            6: "coronaBorealis",
                        },
                        2: {
                            18: "taurus",
                            19: "orion",
                            20: "canisMayor",
                            21: "gemini",
                            22: "cancer",
                            23: "hydra",
                            0: "leo",
                            1: "ursaMayor",
                            2: "crater",
                            3: "virgo",
                            4: "bootes",
                            5: "coronaBorealis",
                            6: "hercules",
                        }
                    },
                    2: {
                        1: {
                            18: "orion",
                            19: "canisMayor",
                            20: "gemini",
                            21: "cancer",
                            22: "hydra",
                            23: "leo",
                            0: "ursaMayor",
                            1: "crater",
                            2: "virgo",
                            3: "bootes",
                            4: "coronaBorealis",
                            5: "hercules",
                        },
                        2: {
                            19: "gemini",
                            20: "cancer",
                            21: "hydra",
                            22: "leo",
                            23: "ursaMayor",
                            0: "crater",
                            1: "virgo",
                            2: "bootes",
                            3: "coronaBorealis",
                            4: "hercules",
                            5: "ophiuchus",
                        }
                    },
                    3: { // April
                        1: {
                            20: "cancer",
                            21: "hydra",
                            22: "leo",
                            23: "ursaMayor",
                            0: "crater",
                            1: "virgo",
                            2: "bootes",
                            3: "coronaBorealis",
                            4: "hercules",
                            5: "ophiuchus",
                        },
                        2: {
                            20: "hydra",
                            21: "leo",
                            22: "ursaMayor",
                            23: "crater",
                            0: "virgo",
                            1: "bootes",
                            2: "coronaBorealis",
                            3: "hercules",
                            4: "ophiuchus",
                        }
                    },
                    4: { // May
                        1: {
                            21: "ursaMayor",
                            22: "crater",
                            23: "virgo",
                            0: "bootes",
                            1: "coronaBorealis",
                            2: "hercules",
                            3: "ophiuchus",
                            4: "sagittarius",
                        },
                        2: {
                            21: "crater",
                            22: "virgo",
                            23: "bootes",
                            0: "coronaBorealis",
                            1: "scorpius",
                            2: "hercules",
                            3: "ophiuchus",
                            4: "sagittarius",
                        }
                    },
                    5: { // June
                        1: {
                            22: "bootes",
                            23: "coronaBorealis",
                            0: "scorpius",
                            1: "hercules",
                            2: "sagittarius",
                            3: "aquila",
                            4: "cygnus",
                        },
                        2: {
                            22: "coronaBorealis",
                            23: "scorpius",
                            0: "hercules",
                            1: "sagittarius",
                            2: "aquila",
                            3: "cygnus",
                            4: "capricornus",
                        },
                    },
                    6: { // July
                        1: {
                            22: "scorpius",
                            23: "hercules",
                            0: "sagittarius",
                            1: "aquila",
                            2: "cygnus",
                            3: "capricornus",
                        },
                        2: {
                            22: "hercules",
                            23: "sagittarius",
                            0: "aquila",
                            1: "cygnus",
                            2: "capricornus",
                            3: "aquarius",
                            4: "pegasus",
                        },
                    },
                    7: { // August
                        1: {
                            21: "hercules",
                            22: "sagittarius",
                            23: "aquila",
                            0: "cygnus",
                            1: "capricornus",
                            2: "aquarius",
                            3: "pegasus",
                            4: "pisces",
                        },
                        2: {
                            21: "sagittarius",
                            22: "aquila",
                            23: "cygnus",
                            0: "capricornus",
                            1: "aquarius",
                            2: "pegasus",
                            3: "pisces",
                            4: "andromeda",
                            5: "triangulum",
                        }
                    },
                    8: { // September
                        1: {
                            20: "sagittarius",
                            21: "aquila",
                            22: "cygnus",
                            23: "capricornus",
                            0: "aquarius",
                            1: "pegasus",
                            2: "pisces",
                            3: "andromeda",
                            4: "triangulum",
                            5: "perseus",
                        },
                        2: {
                            20: "aquila",
                            21: "cygnus",
                            22: "capricornus",
                            23: "aquarius",
                            0: "pegasus",
                            1: "pisces",
                            2: "andromeda",
                            3: "triangulum",
                            4: "perseus",
                            5: "taurus",
                        }
                    },
                    9: { // October
                        1: {
                            19: "sagittarius",
                            20: "aquila",
                            21: "cygnus",
                            22: "aquarius",
                            23: "pegasus",
                            0: "pisces",
                            1: "andromeda",
                            2: "triangulum",
                            3: "perseus",
                            4: "taurus",
                            5: "orion",
                            6: "auriga",
                        },
                        2: {
                            18: "aquila",
                            19: "cygnus",
                            20: "capricornus",
                            21: "aquarius",
                            22: "pegasus",
                            23: "pisces",
                            0: "andromeda",
                            1: "triangulum",
                            2: "perseus",
                            3: "taurus",
                            4: "orion",
                            5: "auriga",
                            6: "gemini",
                            7: "cancer",
                        }
                    },
                    10: { // November
                        1: {
                            17: "cygnus",
                            18: "capricornus",
                            19: "aquarius",
                            20: "pegasus",
                            21: "pisces",
                            22: "andromeda",
                            23: "triangulum",
                            0: "perseus",
                            1: "taurus",
                            2: "orion",
                            3: "canisMayor",
                            4: "gemini",
                            5: "cancer",
                            6: "hydra",
                        },
                        2: {
                            17: "capricornus",
                            18: "aquarius",
                            19: "pegasus",
                            20: "pisces",
                            21: "andromeda",
                            22: "triangulum",
                            23: "perseus",
                            0: "taurus",
                            1: "orion",
                            2: "auriga",
                            3: "gemini",
                            4: "canisMinor",
                            5: "cancer",
                            6: "hydra",
                        }
                    },
                    11: { // December
                        1: {
                            17: "aquarius",
                            18: "pegasus",
                            19: "pisces",
                            20: "andromeda",
                            21: "triangulum",
                            22: "perseus",
                            23: "taurus",
                            0: "orion",
                            1: "canisMayor",
                            2: "gemini",
                            3: "cancer",
                            4: "hydra",
                            5: "leo",
                            6: "crater",
                        },
                        2: {
                            17: "pegasus",
                            18: "pisces",
                            19: "andromeda",
                            20: "triangulum",
                            21: "perseus",
                            22: "taurus",
                            23: "orion",
                            0: "auriga",
                            1: "gemini",
                            2: "cancer",
                            3: "hydra",
                            4: "leo",
                            5: "crater",
                            6: "ursaMayor",
                            7: "virgo",
                        }
                    }
            },
            constellations: {
                bootes: [
                    {top: 35, left: 18, size: 's', twinkle: true},
                    {top: 13, left: 26, size: 's'},
                    {top: 50, left: 36, size: 's'},
                    {top: 19, left: 46, size: 's', twinkle: true},
                    {top: 42, left: 43, size: 's'},
                    {top: 75, left: 59, size: 'l', twinkle: true},
                    {top: 89, left: 49, size: 's'},
                    {top: 77, left: 76, size: 's', twinkle: true},
                ],
                coronaBorealis: [
                    {top: 45, left: 20, size: 's', twinkle: true},
                    {top: 72, left: 30, size: 's'},
                    {top: 78, left: 43, size: 's', twinkle: true},
                    {top: 75, left: 56, size: 's'},
                    {top: 62, left: 72, size: 'm', twinkle: true},
                    {top: 37, left: 75, size: 's'},
                    {top: 25, left: 62, size: 's', twinkle: true},
                ],
                hercules: [
                    {top: 22, left: 33, size: 's', twinkle: true},
                    {top: 57, left: 25, size: 's'},
                    {top: 31, left: 47, size: 's', twinkle: true},
                    {top: 67, left: 45, size: 's'},
                    {top: 50, left: 50, size: 's'},
                    {top: 28, left: 60, size: 's', twinkle: true},
                    {top: 48, left: 62, size: 's', twinkle: true},
                    {top: 10, left: 65, size: 's'},
                    {top: 48, left: 60, size: 's', twinkle: true},
                    {top: 73, left: 73, size: 's'},
                ],
                lyra: [
                    {top: 12, left: 70, size: 'l', twinkle: true},
                    {top: 42, left: 34, size: 's'},
                    {top: 32, left: 55, size: 's', twinkle: true},
                    {top: 77, left: 28, size: 's'},
                    {top: 67, left: 45, size: 's', twinkle: true},
                ],
                aquila: [
                    {top: 73, left: 15, size: 's', twinkle: true},
                    {top: 35, left: 25, size: 's'},
                    {top: 65, left: 33, size: 's'},
                    {top: 25, left: 35, size: 'm', twinkle: true},
                    {top: 20, left: 47, size: 's'},
                    {top: 55, left: 55, size: 's', twinkle: true},
                    {top: 10, left: 75, size: 's'},
                    {top: 85, left: 73, size: 's', twinkle: true},
                ],
                cygnus: [
                    {top: 79, left: 20, size: 's'},
                    {top: 70, left: 38, size: 's', twinkle: true},
                    {top: 30, left: 36, size: 'm', twinkle: true},
                    {top: 52, left: 55, size: 's'},
                    {top: 67, left: 70, size: 's'},
                    {top: 33, left: 75, size: 's'},
                    {top: 15, left: 82, size: 's', twinkle: true},
                    {top: 82, left: 87, size: 's', twinkle: true},
                ],
                capricornus: [
                    {top: 22, left: 12, size: 's', twinkle: true},
                    {top: 24, left: 16, size: 's'},
                    {top: 45, left: 26, size: 's', twinkle: true},
                    {top: 29, left: 31, size: 's'},
                    {top: 32, left: 49, size: 's', twinkle: true},
                    {top: 72, left: 59, size: 's'},
                    {top: 65, left: 65, size: 's', twinkle: true},
                    {top: 29, left: 83, size: 's', twinkle: true},
                    {top: 18, left: 84, size: 's'},
                    {top: 16, left: 89, size: 's'},
                ],
                scorpius: [
                    {top: 66, left: 19, size: 's'},
                    {top: 73, left: 16, size: 's'},
                    {top: 85, left: 24, size: 's', twinkle: true},
                    {top: 84, left: 37, size: 's', twinkle: true},
                    {top: 81, left: 42, size: 's'},
                    {top: 68, left: 47, size: 's'},
                    {top: 52, left: 49, size: 's'},
                    {top: 37, left: 62, size: 's'},
                    {top: 32, left: 68, size: 'm', twinkle: true},
                    {top: 13, left: 86, size: 's'},
                    {top: 23, left: 88, size: 's', twinkle: true},
                    {top: 34, left: 86, size: 's', twinkle: true},
                ],
                sagittarius: [
                    {top: 37, left: 8, size: 's'},
                    {top: 52, left: 17, size: 'sm', twinkle: true},
                    {top: 25, left: 22, size: 's'},
                    {top: 33, left: 37, size: 's', twinkle: true},
                    {top: 20, left: 65, size: 's'},
                    {top: 50, left: 73, size: 's', twinkle: true},
                    {top: 80, left: 67, size: 's'},
                    {top: 57, left: 92, size: 's'},
                ],
                aquarius: [
                    {top: 65, left: 10, size: 's'},
                    {top: 55, left: 25, size: 's'},
                    {top: 45, left: 27, size: 's'},

                    {top: 48, left: 37, size: 's', twinkle: true},
                    {top: 60, left: 40, size: 's'},
                    {top: 75, left: 35, size: 's'},

                    {top: 35, left: 38, size: 's', twinkle: true},
                    {top: 34, left: 45, size: 's'},
                    {top: 38, left: 50, size: 's'},
                    {top: 30, left: 50, size: 's'},
                    {top: 34, left: 58, size: 'sm', twinkle: true},

                    {top: 50, left: 53, size: 's'},
                    {top: 60, left: 60, size: 's'},

                    {top: 43, left: 73, size: 'sm', twinkle: true},
                    {top: 50, left: 90, size: 's'},
                ],
                pegasus: [
                    {top: 55, left: 8, size: 'sm'},
                    {top: 23, left: 15, size: 'sm', twinkle: true},
                    {top: 28, left: 45, size: 'sm'},
                    {top: 60, left: 46, size: 'sm', twinkle: true},
                    {top: 70, left: 63, size: 's'},
                    {top: 80, left: 76, size: 's'},
                    {top: 72, left: 92, size: 's'},
                    {top: 34, left: 55, size: 's'},
                    {top: 20, left: 58, size: 's', twinkle: true},
                    {top: 10, left: 75, size: 's'},
                    {top: 30, left: 77, size: 's'},
                    {top: 27, left: 83, size: 's', twinkle: true},
                ],
                pisces: [
                    {top: 75, left: 13, size: 'sm', twinkle: true},
                    {top: 50, left: 25, size: 's'},
                    {top: 35, left: 35, size: 's'},
                    {top: 25, left: 30, size: 's'},
                    {top: 22, left: 36, size: 's'},
                    {top: 72, left: 28, size: 's', twinkle: true},
                    {top: 70, left: 44, size: 's'},
                    {top: 70, left: 63, size: 's'},
                    {top: 71, left: 78, size: 's'},
                    {top: 67, left: 84, size: 'sm', twinkle: true},
                    {top: 71, left: 91, size: 's'},
                    {top: 79, left: 92, size: 's'},
                    {top: 84, left: 87, size: 's'},
                    {top: 82, left: 75, size: 's', twinkle: true},
                ],
                andromeda: [
                    {top: 18, left: 10, size: 'sm', twinkle: true},
                    {top: 55, left: 42, size: 'sm'},
                    {top: 75, left: 70, size: 's'},
                    {top: 82, left: 90, size: 'sm', twinkle: true},
                    {top: 46, left: 57, size: 's'},
                    {top: 30, left: 68, size: 's'},
                ],
                triangulum: [
                    {top: 37, left: 32, size: 's', twinkle: true},
                    {top: 27, left: 45, size: 's'},
                    {top: 60, left: 62, size: 's', twinkle: true},
                ],
                eridanus: [
                    {top: 13, left: 13, size: 's', twinkle: true},
                    {top: 10, left: 16, size: 's', twinkle: true},
                    {top: 10, left: 28, size: 's'},
                    {top: 13, left: 37, size: 's'},
                    {top: 23, left: 45, size: 'm', twinkle: true},
                    {top: 17, left: 52, size: 's'},
                    {top: 17, left: 56, size: 's', twinkle: true},
                    {top: 17, left: 65, size: 's'},
                    {top: 17, left: 72, size: 's', twinkle: true},
                    {top: 25, left: 76, size: 's'},
                    {top: 33, left: 75, size: 's'},
                    {top: 43, left: 65, size: 's', twinkle: true},
                    {top: 40, left: 58, size: 's', twinkle: true},
                    {top: 40, left: 52, size: 's'},
                    {top: 45, left: 40, size: 's'},
                    {top: 52, left: 38, size: 'sm', twinkle: true},
                    {top: 57, left: 44, size: 's'},
                    {top: 60, left: 40, size: 's'},
                    {top: 68, left: 53, size: 's', twinkle: true},
                    {top: 72, left: 65, size: 's'},
                    {top: 69, left: 70, size: 'sm', twinkle: true},
                    {top: 69, left: 74, size: 's'},
                    {top: 80, left: 73, size: 's'},
                    {top: 87, left: 80, size: 's'},
                    {top: 87, left: 80, size: 's'},
                    {top: 95, left: 83, size: 'l', twinkle: true},
                ],
                perseus: [
                    {top: 40, left: 40, size: 's', twinkle: true},
                    {top: 33, left: 50, size: 'm', twinkle: true},
                    {top: 20, left: 60, size: 's'},
                    {top: 22, left: 65, size: 's'},
                    {top: 13, left: 66, size: 's'},
                    {top: 65, left: 60, size: 'sm', twinkle: true},
                    {top: 70, left: 62, size: 's', twinkle: true},
                    {top: 71, left: 69, size: 's'},
                    {top: 60, left: 32, size: 's'},
                    {top: 71, left: 27, size: 's'},
                    {top: 83, left: 28, size: 's'},
                    {top: 81, left: 33, size: 's'},
                ],
                taurus: [
                    {top: 20, left: 15, size: 'sm', twinkle: true},
                    {top: 40, left: 10, size: 's'},
                    {top: 35, left: 35, size: 's'},
                    // Hyades
                    {top: 48, left: 45, size: 'm', twinkle: true},
                    {top: 42, left: 52, size: 's'},
                    {top: 48, left: 54, size: 's'},
                    {top: 52, left: 54, size: 's'},
                    // --
                    {top: 65, left: 70, size: 's'},
                    {top: 70, left: 85, size: 's'},
                    // Pleiades
                    {top: 29, left: 67, size: 's', twinkle: true},
                    {top: 30, left: 70, size: 's'},
                    {top: 28, left: 73, size: 's', twinkle: true},
                    {top: 32, left: 75, size: 's'},
                    {top: 31, left: 77, size: 's', twinkle: true},
                ],
                orion: [
                    {top: 18, left: 31, size: 'm', twinkle: true}, // Betelgeuse
                    {top: 11, left: 50, size: 's'},
                    {top: 26, left: 59, size: 's'}, // Bellatrix
                    // belt stars
                    {top: 55, left: 45, size: 's'},
                    {top: 51, left: 48, size: 's', twinkle: true},
                    {top: 48, left: 51, size: 's'},
                    {top: 82, left: 34, size: 's'}, // Saiph
                    {top: 78, left: 63, size: 'm', twinkle: true}, // Rigel
                ],
                auriga: [
                    {top: 25, left: 35, size: 's'},
                    {top: 20, left: 55, size: 'm', twinkle: true}, // Capella
                    {top: 27, left: 65, size: 's'},
                    {top: 35, left: 63, size: 's'},
                    {top: 50, left: 30, size: 's', twinkle: true},
                    {top: 75, left: 52, size: 'sm', twinkle: true},
                    {top: 65, left: 70, size: 's'},
                ],
                gemini: [
                    {top: 20, left: 25, size: 'sm', twinkle: true}, // Castor
                    {top: 35, left: 15, size: 'sm', twinkle: true}, // Pollux
                    {top: 47, left: 14, size: 's', twinkle: true},
                    {top: 30, left: 40, size: 's'},
                    {top: 45, left: 55, size: 's'},
                    {top: 60, left: 70, size: 's'},
                    {top: 61, left: 74, size: 's'},
                    {top: 58, left: 79, size: 's'},
                    {top: 55, left: 32, size: 's'},
                    {top: 60, left: 41, size: 's'},
                    {top: 55, left: 32, size: 's'},
                    {top: 80, left: 64, size: 'sm', twinkle: true}, // Alhena
                ],
                cancer: [
                    {top: 18, left: 45, size: 's', twinkle: true},
                    {top: 42, left: 46, size: 's'},
                    {top: 53, left: 45, size: 's', twinkle: true},
                    {top: 72, left: 35, size: 's'},
                    {top: 82, left: 70, size: 's'},
                ],
                canisMayor: [
                    {top: 20, left: 38, size: 's'},
                    {top: 22, left: 58, size: 'l', twinkle: true}, // Sirius
                    {top: 26, left: 87, size: 's'},
                    {top: 81, left: 21, size: 's'},
                    {top: 70, left: 40, size: 's'},
                    {top: 78, left: 53, size: 's'},
                ],
                hydra:[
                    {top: 68, left: 4, size: 's'},
                    {top: 62, left: 15, size: 's'},
                    {top: 75, left: 35, size: 's', twinkle: true},
                    {top: 71, left: 42, size: 's'},
                    {top: 53, left: 48, size: 's'},
                    {top: 50, left: 52, size: 's'},
                    // {top: 49, left: 56, size: 's'},
                    {top: 49, left: 62, size: 's'},
                    // {top: 46, left: 66, size: 's'},
                    {top: 47, left: 72, size: 's'},
                    {top: 45, left: 80, size: 'sm', twinkle: true},
                    {top: 30, left: 79, size: 's'},
                    // {top: 28, left: 88, size: 's'},
                    // head
                    {top: 27, left: 89, size: 'sm', twinkle: true},
                    {top: 27, left: 94, size: 'sm', twinkle: true},
                    {top: 32, left: 91, size: 's'},
                    {top: 32, left: 94, size: 's'},
                ],
                canisMinor:[
                    {top: 60, left: 35, size: 'm', twinkle: true},
                    {top: 40, left: 60, size: 's'},
                ],
                leo: [
                    {top: 72, left: 15, size: 'sm', twinkle: true},
                    {top: 50, left: 35, size: 's'},
                    {top: 65, left: 38, size: 's'},
                    {top: 60, left: 82, size: 'm', twinkle: true}, // Regulus
                    {top: 50, left: 81, size: 's'},
                    {top: 45, left: 72, size: 's'},
                    {top: 32, left: 70, size: 's', twinkle: true},
                    {top: 23, left: 80, size: 's', twinkle: true},
                    {top: 29, left: 85, size: 's'},
                ],
                crater: [
                    {top: 55, left: 20, size: 's'},
                    {top: 60, left: 36, size: 's', twinkle: true},
                    {top: 57, left: 52, size: 's'},
                    {top: 45, left: 58, size: 's'},
                    {top: 27, left: 52, size: 's', twinkle: true},
                    {top: 23, left: 35, size: 's'},
                    {top: 56, left: 74, size: 's'},
                    {top: 75, left: 63, size: 's'},
                ],
                ursaMayor:[
                    {top: 47, left: 10, size: 'sm', twinkle: true},
                    {top: 40, left: 28, size: 'sm'},
                    {top: 45, left: 42, size: 'sm', twinkle: true},
                    {top: 50, left: 55, size: 'sm', twinkle: true},
                    {top: 62, left: 60, size: 'sm', twinkle: true},
                    {top: 62, left: 83, size: 'sm'},
                    {top: 47, left: 86, size: 'sm', twinkle: true},
                ],
                virgo:[
                    {top: 40, left: 10, size: 's', twinkle: true},
                    {top: 53, left: 9, size: 's'},
                    {top: 50, left: 42, size: 's', twinkle: true},
                    {top: 62, left: 18, size: 's', twinkle: true},
                    {top: 72, left: 40, size: 'm', twinkle: true},
                    {top: 30, left: 60, size: 's'},
                    {top: 40, left: 60, size: 's', twinkle: true},
                    {top: 52, left: 65, size: 'sm', twinkle: true},
                    {top: 53, left: 78, size: 's', twinkle: true},
                    {top: 43, left: 95, size: 's', twinkle: true},
                ],
                ophiuchus: [
                    {top: 72, left: 20, size: 's'},
                    {top: 38, left: 24, size: 's'},
                    {top: 33, left: 26, size: 's'},
                    {top: 15, left: 30, size: 'sm'},
                    {top: 86, left: 50, size: 'sm'},
                    {top: 23, left: 60, size: 's'},
                    {top: 75, left: 72, size: 's'},
                    {top: 40, left: 77, size: 's'},
                    {top: 62, left: 82, size: 's'},
                    {top: 58, left: 85, size: 's'},
                ]
            },
            startFields: [
                [
                    {top: 60, left: 20, size: 's'},
                    {top: 15, left: 82, size: 's', twinkle: true},
                    {top: 82, left: 65, size: 's', twinkle: true},
                ],
                [
                    {top: 79, left: 39, size: 's'},
                    {top: 70, left: 15, size: 's'},
                    {top: 15, left: 25, size: 'm', twinkle: true},
                    {top: 70, left: 70, size: 's', twinkle: true},
                ],
                [
                    {top: 69, left: 30, size: 'm'},
                    {top: 10, left: 75, size: 's', twinkle: true},
                    {top: 85, left: 50, size: 's', twinkle: true},
                ],
                [
                    {top: 69, left: 30, size: 's', twinkle: true},
                    {top: 46, left: 90, size: 's'},
                    {top: 25, left: 80, size: 's', twinkle: true},
                ],
                [
                    {top: 10, left: 18, size: 's', twinkle: true},
                    {top: 12, left: 80, size: 's', twinkle: true},
                    {top: 60, left: 65, size: 's', twinkle: false},
                    {top: 79, left: 40, size: 's', twinkle: true},
                ],
                [
                    {top: 85, left: 90, size: 's'},
                    {top: 10, left: 15, size: 'm', twinkle: true},
                    {top: 38, left: 34, size: 's'},
                    {top: 74, left: 40, size: 's', twinkle: true},
                ],
                [
                    {top: 80, left: 80, size: 's'},
                    {top: 20, left: 10, size: 's', twinkle: true},
                    {top: 84, left: 36, size: 's', twinkle: true},
                ],
                [
                    {top: 20, left: 10, size: 's', twinkle: true},
                    {top: 38, left: 65, size: 's'},
                    {top: 84, left: 36, size: 's', twinkle: true},
                ],
                [
                    {top: 10, left: 96, size: 's'},
                    {top: 56, left: 34, size: 'm', twinkle: true},
                    {top: 44, left: 86, size: 'm', twinkle: true},
                    {top: 92, left: 23, size: 's', twinkle: true},
                ],
                [
                    {top: 10, left: 96, size: 's'},
                    {top: 44, left: 86, size: 's', twinkle: true},
                    {top: 92, left: 23, size: 's', twinkle: true},
                ],
                [
                    {top: 10, left: 96, size: 's'},
                    {top: 34, left: 65, size: 's'},
                    {top: 92, left: 23, size: 's', twinkle: true},
                ],
                [
                    {top: 55, left: 52, size: 's'},
                    {top: 15, left: 17, size: 's', twinkle: true},
                    {top: 5, left: 65, size: 's', twinkle: true},
                ],
                [
                    {top: 47, left: 12, size: 's'},
                    {top: 15, left: 69, size: 'm', twinkle: true},
                    {top: 33, left: 73, size: 's', twinkle: true},
                ],
            ]
        }
    },
    computed: {
        ...mapGetters('forecastsStore', ['getForecastDate']),
        getDots() {
            const name = this.getConstellationName;
            if (name) {
                if (this.moonVisible) {

                    const stars = [];
                    const count = Math.max(2, Math.ceil(Math.random() * 4));

                    let totalTop = 0;
                    let totalLeft = 0;

                    for (let i = 0; i < count; i++) {
                        // const sizeBase = Math.floor(Math.random() * 2);
                        // const size = sizeBase === 1 ? 'm' : 's';
                        const size = 's';
                        let left = Math.floor(Math.random() * 80) + 10;
                        totalLeft += left;
                        let top = Math.floor(Math.random() * 80) + 10;
                        totalTop += top;

                        const equalizer = function (total, no, val) {
                            const avg = total / (no + 1);
                            if (no > 2) {
                                if ((val < 60 && avg < 45)) {
                                    val += 40;
                                } else if (val > 60 && avg > 55) {
                                    val -= 40;
                                }
                            }
                            return val;
                        }

                        left = equalizer(totalLeft, i, left);
                        top = equalizer(totalTop, i, top);

                        const star = {top: top, left: left, size: size, twinkle: true};
                        stars.push(star);
                    }

                    // const rndFieldIdx = Math.floor(Math.random() * Math.random() * this.startFields.length);
                    // console.log("rndFieldIdx = " + rndFieldIdx)
                    // return this.startFields[rndFieldIdx];
                    return stars;
                } else {
                    const dots = this.constellations[name];
                    if (dots) {
                        return dots;
                    }
                }
            }
            return [];
        },
        getConstellationName() {
            const date = new Date(this.getForecastDate);
            const month = date.getMonth();
            const day = date.getDate();
            const partOfMonth =  day <= 15 ? 1 : 2;
            const monthConstellations = this.displayConstellation[month];
            if (monthConstellations) {
                const partOfMonthConstellations = monthConstellations[partOfMonth];
                if (partOfMonthConstellations) {
                    const hourConstellation = partOfMonthConstellations[this.hour];
                    if (hourConstellation) {
                        return hourConstellation;
                    }
                }
            }
            return null;
        }
    },
    methods: {
        getDotStyle(dot) {
            const size = dot.size === 's' ? 1 : dot.size === 'sm' ? 1.5 : dot.size === 'm' ? 2 : 3 ;
            if (dot.twinkle) {
                return `top: ${dot.top}%; left: ${dot.left}%; width: ${size}px; height: ${size}px; background-color: ${this.backgroundColor}`
            } else {
                return `top: ${dot.top}%; left: ${dot.left}%; width: ${size}px; height: ${size}px`
            }
        },
    },
    components: {
    }
}

</script>

<style scoped>

.constellation .dot {
    position: absolute;
    background-color: white;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    z-index: 1;
    display: block; /* Ensures inline-block-like behavior */
}

</style>
