// noinspection JSNonASCIINames
export class Sources {


    // noinspection NonAsciiCharacters
    static sources = {
        "ACCUWEATHER_WEB": "AccuWeather",
        "ALADIN_API": "Aladin",
        "METEOSOURCE_API": "Clear Outside",
        "OPEN_METEO_ECMWF": "ECMWF",
        "OPEN_METEO_GFS": "GFS",
        "OPEN_METEO_ICON": "ICON D2",
        // "CLEAR_OUTSIDE_WEB": "Clear Outside",
        // "METEOBLUE_API": "MeteoblueA",
        "MET_NORWAY_API": "MET Norway",
        "METEOBLUE_WEB": "Meteoblue",
        // "CHMU_WEB": "ČHMÚ", // TODO do not include here ...
    }

    static sourceCodes = Object.keys(Sources.sources);

    // TODO sort them ...
    static getSourceCodes() {
        return Sources.sourceCodes;
    }

    static displayName(sourceCode) {
        return (Sources.sources)[sourceCode];
    }

}
