import Vue from 'vue';
import VueRouter from 'vue-router';
import About from "@/components/about/About.vue";
import NotFound from "@/components/reusables/NotFound";
import Cookies from "@/components/Cookies";
import TermsAndConditions from "@/components/TermsAndConditions";
import ForecastSearch from "@/components/forecast/ForecastSearch";
import VerifiedForecasts from "@/components/verification/VerifiedForecasts.vue";
import Homepage from "@/components/Homepage";
import {Params} from "@/router/params";
import Changelog from "@/components/changelog/Changelog.vue";

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history', // IMPORTANT: this makes Vue Router use the Browser Router
    routes: [
        {path: '/', component: Homepage},
        {path: '/spolehlivost', component: VerifiedForecasts},
        {path: '/projekt', component: About},
        {path: '/aktuality', component: Changelog},
        {path: '/predpovedi', component: ForecastSearch},
        {path: '/predpovedi/:uuid', component: ForecastSearch},
        {path: '/cookies', component: Cookies},
        {path: '/podminky-uzivani', component: TermsAndConditions},
        {path: '*', component: NotFound}
    ]
})

// Attempts to update the path when it contains the uuid but does not work (uuid not passed to component)
router.beforeEach((to, from, next) => {
    // Update the URL to a different value
    if (to.fullPath.startsWith('/predpovedi/')) {
        const parameter = to.params.uuid;
        // console.log("Parameter  = " + parameter)
        // console.log("Url  = " + to.fullPath)
        Params.forecastUuid = parameter;
        // console.log("forecastUuid  = " + forecastUuid.uuid)
        next(`/predpovedi`);
    } else {
        next(); // Proceed with the route change
    }
});

// // eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0)
//     // ...
// })

