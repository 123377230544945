<template>

    <div>
        <div style="margin-bottom: 20px">
            <div class="text-center" style="margin-bottom: 20px">
                <h3>Vyhodnocení posledních hodin</h3>
            </div>
            <p>
                Porovnání aktuálních předpovědí celkové oblačnosti s naměřenou úrovní za posledních několik hodin zpětně
                v celé České republice nebo vybraných lokalitách.
            </p>
        </div>

        <div v-if="pastHoursExist" class="accordion">
            <b-card v-if="this.pastHoursExistFor(1)" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-0" style="height: 35px">
                    <b-button block v-b-toggle.accordion-1 variant="light">1 hodina</b-button>
                </b-card-header>
                <b-collapse visible id="accordion-1" class="mt-0">
                    <b-card-body style="padding-bottom: 0">
                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=1></VerificationsTable>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card v-if="this.pastHoursExistFor(3)" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-0" style="height: 35px">
                    <b-button block v-b-toggle.accordion-3 variant="light">3 hodiny</b-button>
                </b-card-header>
                <b-collapse :visible="!this.pastHoursExistFor(1)" id="accordion-3" class="mt-0">
                    <b-card-body style="padding-bottom: 0">
                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=3></VerificationsTable>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card v-if="this.pastHoursExistFor(6)" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-0" style="height: 35px">
                    <b-button block v-b-toggle.accordion-6 variant="light">6 hodin</b-button>
                </b-card-header>
                <b-collapse :visible="!this.pastHoursExistFor(3)" id="accordion-6" class="mt-0">
                    <b-card-body style="padding-bottom: 0">
                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=6></VerificationsTable>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card v-if="this.pastHoursExistFor(12)" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-0" style="height: 35px">
                    <b-button block v-b-toggle.accordion-12 variant="light">12 hodin</b-button>
                </b-card-header>
                <b-collapse :visible="!this.pastHoursExistFor(6)" id="accordion-12" class="mt-0">
                    <b-card-body style="padding-bottom: 0">
                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=12></VerificationsTable>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card v-if="this.pastHoursExistFor(24)" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-0" style="height: 35px">
                    <b-button block v-b-toggle.accordion-24 variant="light">24 hodin</b-button>
                </b-card-header>
                <b-collapse :visible="!this.pastHoursExistFor(12)" id="accordion-24" class="mt-0">
                    <b-card-body style="padding-bottom: 0">
                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=24></VerificationsTable>
                    </b-card-body>
                </b-collapse>
            </b-card>
<!--            <b-card no-body class="mb-1">-->
<!--                <b-card-header header-tag="header" class="p-0">-->
<!--                    <b-button block v-b-toggle.accordion-36 variant="light">Posledních 36 hodin</b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse id="accordion-36" class="mt-0">-->
<!--                    <b-card-body style="padding-bottom: 0">-->
<!--                        <VerificationsTable v-if="pastHoursExist" v-bind:hours=36></VerificationsTable>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
        </div>

        <div style="margin-top: 27px">
            <DiffsExplained></DiffsExplained>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import VerificationsTable from "@/components/verification/VerificationsTable.vue";
import {BCard} from 'bootstrap-vue';
import {BCardHeader} from 'bootstrap-vue';
import {BCollapse} from 'bootstrap-vue';
import {BCardBody} from 'bootstrap-vue';
import {BButton} from 'bootstrap-vue';
import {VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import DiffsExplained from "@/components/verification/DiffsExplained.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'HourlyVerifications',
    mixins: [utilsMixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters('verificationsStore', ['pastHoursExist', 'pastHoursExistFor'])
    },
    methods: {},
    components: {
        VerificationsTable,
        BCollapse,
        BCardBody,
        BCardHeader,
        BCard,
        BButton,
        DiffsExplained
    }
}
</script>
