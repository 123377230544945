<template>

    <div>
        <p>
            Kladná <span class="bold-words">prostá odchyka</span> značí, že je předpověď celkově optimističtější,
            než byl reálný pozorovaný stav. Naopak záporná odchyka ukazuje na pesimistickou předpověď.
        </p>
        <p>
            Průměrování více prostých odchylek, ke kterému dochází při výběru více lokalit zároveň, může dát malou výslednou celkovou odchylku, přestože dílčí odchylky mohly být poměrně velké na kladnou i zápornou stranu.
            Z tohoto pohledu je více vypovídající <span class="bold-words">absolutní odchyka</span>,
            která vzniká průměrem absolutních hodnot odchylek.
        </p>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "DiffsExplained",
    computed: {
        ...mapGetters([])
    },
    methods: {
    },
    components: {}
}

</script>
