<template>
    <div class="d-flex justify-content-center align-items-center">
        <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                    id="cloud-option-radios"
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    size="sm"
                    name="radio-btn-outline"
                    buttons
            >
                <b-form-radio value="total_icon">
                    <img style="width: 15px; height: 15px; padding-bottom: 2px"  alt="Clouds" :src="require('@/assets/icons/meteoblue/weather_pictograms/svg/'+ '04_night.svg')"/>
                    Ikony
                </b-form-radio>

                <b-form-radio value="total">
                    Celková
                </b-form-radio>

                <b-form-radio value="high">
                    Vysoká
                </b-form-radio>

                <b-form-radio value="middle">
                    Střední
                </b-form-radio>

                <b-form-radio value="low">
                    Nízká
                </b-form-radio>
            </b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue';
import {Utils} from "@/utils/Utils";

export default {
    name: "ForecastCloudOptions",
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', ['getDisplayedCloudType']),
    },
    watch: {
        selected: function () {
            this.changeDisplayedCloudType(this.selected);
        }
    },
    data() {
        return {
            selected: 'total',
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['changeDisplayedCloudType']),
    },
    mounted() {
        this.selected = this.getDisplayedCloudType;
    },
    components: {
        BFormGroup, BFormRadioGroup, BFormRadio
    }
}
</script>

<style>
/* Add any additional styles if needed */
</style>
