<template>

        <div v-if="getForecastTrend || getForecastStatus">
                <div style="margin-bottom: 12px" class="d-flex justify-content-center">
                    <div class="trend-desc" :style="getStyle()" style="cursor: pointer" v-on:click="toggleTrendPopup()">
                    <span style="margin-right: 6px;">
                        <span style="vertical-align: middle">
                            {{getForecastStatus.description}}
                        </span>
                        <span style="vertical-align: middle" v-if="getForecastStatus.wholeCountry === false && getForecastStatus.radiusKm">
                            v
                            <span style="font-size: 17px; vertical-align: middle; margin-bottom: 2px" class="material-symbols-outlined">
                                settings_backup_restore
                            </span>
                            {{getForecastStatus.radiusKm}} km
                        </span>

                    </span>

                        <b-icon-info-circle size="sm" variant="info" style="margin-bottom: 2px; cursor: pointer;"/>
                    </div>

                    <div class="overlay" v-if="trendPopupDisplayed" @click="toggleTrendPopup"></div>
                    <div class="overlay" v-if="reliabilityPopupDisplayed" @click="toggleReliabilityPopup"></div>

                    <Transition>
                        <div class="popup" v-if="trendPopupDisplayed">
                            <div class="ml-auto mr-3" v-on:click="toggleTrendPopup()">
                                <span class="popup-close">&times;</span>
                            </div>
                            <div style="text-align: center; margin-bottom: 20px">
                                <h4 class="popup-title">Jak se mění předpovědi na noc</h4>
                                <h4 class="trend-popup-subtitle">{{this.getDate()}}</h4>
                                <hr>
                                <p class="popup-text">Graf zachycuje změny předpovědí oblačnosti na noc za posledních
                                    {{getForecastTrend.trendIntervalHours}} hodin pro širší region vyhledané lokality.
                                    Hodnoty jsou průměrované z dat dostupných modelů, aby postihly obecný trend.
                                </p>
                            </div>
                            <ForecastTrendChart></ForecastTrendChart>
                        </div>
                    </Transition>

                    <Transition>
                        <div class="popup" v-if="reliabilityPopupDisplayed">
                            <div class="ml-auto mr-3" v-on:click="toggleReliabilityPopup()">
                                <span class="popup-close">&times;</span>
                            </div>
                            <div style="text-align: center; margin-bottom: 10px">
                                <h4 class="popup-title">Aktuální přesnost předpovědí</h4>
                                <h4 class="trend-popup-subtitle" style="vertical-align: middle"
                                    v-if="getForecastStatus.wholeCountry === false && getForecastStatus.radiusKm">
                                    (v okruhu {{getForecastStatus.radiusKm}} km)
                                </h4>
                                <hr>
                                <ForecastStatusPastHourVerificationTable></ForecastStatusPastHourVerificationTable>
                                <p v-if="getLastUpdate" style="font-size: 12px">
                                    Aktualizováno: {{getLastUpdate()}}
                                </p>
                            </div>
                        </div>
                    </Transition>
                </div>
        </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {BIconInfoCircle} from "bootstrap-vue";
import ForecastTrendChart from "@/components/forecast/ForecastTrendChart";
import {Utils} from "@/utils/Utils";
import ForecastStatusPastHourVerificationTable from "@/components/forecast/ForecastStatusPastHourVerificationTable.vue";

export default {
    name: "ForecastStatusPopup",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastStatus', 'getForecastTrend',
            'getForecastStatusType', 'getPageTitle', 'todayForecastDisplayed']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
            trendPopupDisplayed: false,
            reliabilityPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getStyle() {
            const color = this.getForecastStatus.color;
            if (color === 'RED') {
                return "color: rgb(236 89 89); background-color: rgb(255 243 243)";
            } else if (color === 'ORANGE') {
                return "color: rgb(253 154 27); background-color: rgb(253 247 235)";
            } else if (color === 'GREEN') {
                return "color: #4ba74b; background-color: rgb(233 247 218)";
            } else if (color === 'GREY') {
                return "color: grey; background-color: rgb(245, 245, 245)";
            }
        },
        toggleTrendPopup() {
            if (this.getForecastStatusType === 'RELIABILITY_ALERT') {
                this.reliabilityPopupDisplayed = !this.reliabilityPopupDisplayed;
            } else {
                this.trendPopupDisplayed = !this.trendPopupDisplayed;
            }
        },
        toggleReliabilityPopup() {
            this.reliabilityPopupDisplayed = !this.reliabilityPopupDisplayed;
        },
        getDate() {
            return Utils.formatDate(new Date(this.getForecastTrend.date), true, false, true);
        },
        getLastUpdate() {
            if (this.getForecastStatus.lastUpdate) {
                return Utils.formatToTime_HHmm(new Date(this.getForecastStatus.lastUpdate));
            }
            return null;
        },
    },
    components: {
        ForecastStatusPastHourVerificationTable,
        BIconInfoCircle, ForecastTrendChart
    }
}
</script>

<style scoped>

/*Must precede media queries*/
.popup-title {
    font-size: medium;
}

.trend-popup-subtitle {
    color: #000078;
    font-size: medium;
}


.trend-desc {
    padding: 4px 17px;
    text-align: center;
    border-radius: 2.5%;
    white-space: nowrap;
    height: 31px;
}

@media (max-width: 370px) {
    .trend-desc {
        font-size: 13px;
        padding: 4px 12px;
    }
    .trend-popup-subtitle {
        color: #000078;
        font-size: small;
    }
}

@media (min-width: 371px) and (max-width: 699px) {
    .trend-desc {
        font-size: 13px;
        padding: 4px 12px;
    }
}

@media (min-width: 700px) {
    .trend-desc {
        font-size: 14px;
    }
}

</style>
