<template>
    <div>

        <b-button size="sm" @click="togglePopup" variant="primary">
            <SettingsIcon name="settings"></SettingsIcon>
            Indikátor aktuální přesnosti
        </b-button>

        <div class="overlay" v-if="popupDisplayed" @click="togglePopup"></div>

        <Transition>
            <div v-if="popupDisplayed" class="popup">
                <div class="ml-auto mr-3" v-on:click="togglePopup">
                    <span class="popup-close">&times;</span>
                </div>
                <div style="text-align: center; margin-bottom: 30px">
                    <h4 class="popup-title">Indikátor aktuální přesnosti modelů</h4>
                    <hr/>
                    <div style="margin-bottom: 15px">
                        <h6>Kolik posledních hodin vyhodnocovat:</h6>
                        <b-form-select v-model="reliabilityType" size="sm" :options="radiusOptions" right variant="primary"></b-form-select>
                    </div>
                    <p class="popup-text">
                        Udávané aktuální počty měření stavu oblačnosti odpovídají vašemu zvolenému širšímu regionu.
                        Tato měření vstupují do vyhodnocení přesnosti modelů.
                    </p>
                </div>

                <ForecastReliabilityScaleLegend style="margin-bottom: 20px" class="popup-text">
                </ForecastReliabilityScaleLegend>

                <div style="text-align: center; margin-bottom: 20px">
                    <b-button @click="clickOk" variant="primary" >
                        Nastavit
                    </b-button>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormSelect } from "bootstrap-vue";
import ForecastReliabilityScaleLegend from "@/components/forecast/ForecastReliabilityScaleLegend.vue";
import SettingsIcon from "@/components/forecast/SettingsIcon.vue";

export default {
    name: "ForecastSourceReliabilityIndicatorSettingsPopup",
    computed: {
        ...mapGetters('forecastsStore', ['isDisplayedWholeCountry', 'getReliabilityMeasurementCount',
            'getReliabilityMeasurementCountForType',
            'getForecastReliabilityType', 'isDisplayedWholeCountry', 'getForecasts']),
    },
    mounted() {
        this.reliabilityType = this.getForecastReliabilityType; // TODO this causes the API to be called twice ...
        console.log("mounted forecastReliabilityType = " + this.getForecastReliabilityType)
        this.setRadiusOptionText();
    },
    data() {
        return {
            popupDisplayed: false,
            reliabilityType: undefined,
            radiusOptions: [
                { value: 'LAST_24_HOURS', displayText: '24 hodin' },
                { value: 'LAST_24_AND_3_HOURS', displayText: '24 hodin s větší váhou posledních 3 hodin' },
                { value: 'LAST_12_HOURS', displayText: '12 hodin' },
                { value: 'LAST_12_AND_3_HOURS', displayText: '12 hodin s větší váhou posledních 3 hodin' },
                { value: 'LAST_6_HOURS', displayText: '6 hodin' },
                { value: 'LAST_6_AND_2_HOURS', displayText: '6 hodin s větší váhou posledních 2 hodin' },
                { value: 'LAST_3_HOURS', displayText: '3 hodiny' },
                { value: 'LAST_1_HOUR', displayText: '1 hodina' }
            ]
        }
    },
    watch: {
        reliabilityType: function (newValue, oldValue) {
            console.log("watch ... reliabilityType oldValue = " + oldValue + "; newValue = " + newValue)
            if (oldValue !== undefined) {
                console.log("reliabilityType = " + newValue);
                this.changeForecastReliabilityType(newValue);
            }
        },
        // eslint-disable-next-line no-unused-vars
        getForecasts: function (newValue, oldValue) {
            // needed to refresh values if user changes radius
            console.log("Inside watch for getReliabilityMeasurementCountForType")
            this.setRadiusOptionText();
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['reloadPartialForecastsUpdate', 'changeForecastReliabilityType']),
        clickOk() {
            this.togglePopup();
            this.reloadPartialForecastsUpdate();
        },
        togglePopup() {
            this.popupDisplayed = !this.popupDisplayed;
            if (this.popupDisplayed) {
                this.$emit('sourceReliabilityIndicatorSettingsPopupDisplayed', 'Hello from the child!');
            } else {
                this.$emit('sourceReliabilityIndicatorSettingsPopupClosed', 'Hello from the child!');

            }
        },
        setRadiusOptionText() {
            this.radiusOptions.forEach(o => {
                o.text = o.displayText + this.getMeasurements(o.value);
            })
        },
        getMeasurements(type) {
            const count = this.getReliabilityMeasurementCountForType(type);
            if (count != null && count >= 0) {
                return ` (${count} měření)`;
            }
            return "";
        },
    },
    components: {
        SettingsIcon,
        ForecastReliabilityScaleLegend,
        BButton,
        BFormSelect
    }
}
</script>

<style scoped>

</style>
