<template>

    <div class="d-flex justify-content-center align-items-center">
        <b-form-group v-slot="{ ariaDescribedby }">
            <div class="row">
                <h6 style="margin-bottom: 13px">
                    Možnosti zobrazení
                </h6>
            </div>
            <div class="row">
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="diff" style="margin-bottom: 3px">
                    Ø prosté odchylky
                </b-form-radio>
            </div>
            <div class="row">
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="diff-abs">
                    Ø absolutní odchylky
                </b-form-radio>
            </div>
        </b-form-group>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";
import {BFormGroup} from 'bootstrap-vue';
import {BFormRadio} from 'bootstrap-vue';

export default {
    name: "ChartsOptions",
    computed: {
        ...mapGetters('verificationsStore', ['getDisplayedDailyChartDiffType']),
    },
    watch: {
        selected: function () {
            // console.log("selected: " + this.selected)
            this.toggleDisplayedDailyChartDiffType(this.selected);
        }
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        ...mapActions('verificationsStore', ['toggleDisplayedDailyChartDiffType']),
    },
    mounted() {
        this.selected = this.getDisplayedDailyChartDiffType;
    },
    components: {
        BFormGroup, BFormRadio
    }
}
</script>

<style>
</style>
