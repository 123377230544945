// noinspection JSNonASCIINames
export class Locations {

    // noinspection NonAsciiCharacters
    static locationsByCounty = {
        "Hlavní město Praha": ["Praha-Kbely", "Praha-Ruzyně"],
        "Jihomoravský kraj": ["Kuchařovice", "Brno-Tuřany", "Hodonín", "Letovice"],
        "Jihočeský kraj": ["České Budějovice", "Temelín", "Kocelovice", "Churáňov", "Vyšši Brod", "Jindřichův Hradec"],
        "Karlovarský kraj": ["Karlovy Vary", "Cheb", "Žlutice"],
        "Kraj Vysočina": ["Náměšť nad Oslavou", "Kostelní Myslová", "Pelhřimov", "Přibyslav"],
        "Královéhradecký kraj": ["Pec pod Sněžkou", "Polom", "Jičín", "Dvůr Králové nad Labem"],
        "Liberecký kraj": ["Liberec"],
        "Moravskoslezský kraj": ["Červená u Libavé", "Ostrava-Mošnov", "Lysá hora", "Krnov"],
        "Olomoucký kraj": ["Prostějov", "Luká", "Šerák"],
        "Pardubický kraj": ["Ústí nad Orlicí", "Pardubice", "Svratouch"],
        "Plzeňský kraj": ["Přimda", "Plzeň-Mikulka", "Klatovy"],
        "Středočeský kraj": ["Čáslav", "Sedlčany", "Rakovník", "Mladá Boleslav", "Vlašim", "Hořovice"],
        "Zlínský kraj": ["Holešov", "Valašské Klobouky"],
        "Ústecký kraj": ["Milešovka", "Tušimice", "Štětí", "Česká Kamenice"],
    }

    // noinspection NonAsciiCharacters
    static countyByLocation = {
        "Praha-Kbely": "Hlavní město Praha",
        "Praha-Ruzyně": "Hlavní město Praha",
        "Kuchařovice": "Jihomoravský kraj",
        "Brno-Tuřany": "Jihomoravský kraj",
        "Hodonín": "Jihomoravský kraj",
        "Letovice": "Jihomoravský kraj",
        "České Budějovice": "Jihočeský kraj",
        "Temelín": "Jihočeský kraj",
        "Kocelovice": "Jihočeský kraj",
        "Churáňov": "Jihočeský kraj",
        "Vyšši Brod": "Jihočeský kraj",
        "Jindřichův Hradec": "Jihočeský kraj",
        "Karlovy Vary": "Karlovarský kraj",
        "Cheb": "Karlovarský kraj",
        "Žlutice": "Karlovarský kraj",
        "Náměšť nad Oslavou": "Kraj Vysočina",
        "Kostelní Myslová": "Kraj Vysočina",
        "Pelhřimov": "Kraj Vysočina",
        "Přibyslav": "Kraj Vysočina",
        "Pec pod Sněžkou": "Královéhradecký kraj",
        "Polom": "Královéhradecký kraj",
        "Jičín": "Královéhradecký kraj",
        "Dvůr Králové nad Labem": "Královéhradecký kraj",
        "Liberec": "Liberecký kraj",
        "Červená u Libavé": "Moravskoslezský kraj",
        "Ostrava-Mošnov": "Moravskoslezský kraj",
        "Lysá hora": "Moravskoslezský kraj",
        "Krnov": "Moravskoslezský kraj",
        "Prostějov": "Olomoucký kraj",
        "Luká": "Olomoucký kraj",
        "Šerák": "Olomoucký kraj",
        "Ústí nad Orlicí": "Pardubický kraj",
        "Pardubice": "Pardubický kraj",
        "Svratouch": "Pardubický kraj",
        "Přimda": "Plzeňský kraj",
        "Plzeň-Mikulka": "Plzeňský kraj",
        "Klatovy": "Plzeňský kraj",
        "Čáslav": "Středočeský kraj",
        "Sedlčany": "Středočeský kraj",
        "Rakovník": "Středočeský kraj",
        "Mladá Boleslav": "Středočeský kraj",
        "Vlašim": "Středočeský kraj",
        "Hořovice": "Středočeský kraj",
        "Holešov": "Zlínský kraj",
        "Valašské Klobouky": "Zlínský kraj",
        "Milešovka": "Ústecký kraj",
        "Tušimice": "Ústecký kraj",
        "Štětí": "Ústecký kraj",
        "Česká Kamenice": "Ústecký kraj",
    }

    static countyById = {
        JC: "Jihočeský kraj",
        JM: "Jihomoravský kraj",
        KA: "Karlovarský kraj",
        KR: "Královéhradecký kraj",
        LI: "Liberecký kraj",
        MO: "Moravskoslezský kraj",
        OL: "Olomoucký kraj",
        PA: "Pardubický kraj",
        ZL: "Zlínský kraj",
        PL: "Plzeňský kraj",
        PR: "Hlavní město Praha",
        ST: "Středočeský kraj",
        US: "Ústecký kraj",
        VY: "Kraj Vysočina"
    }

    static getLocationsByCounty(county) {
        return (Locations.locationsByCounty)[county];
    }

    static getCountyByLocation(location) {
        return (Locations.countyByLocation)[location];
    }

    static getCountyById(id) {
        return Locations.countyById[id];
    }

    static locationsAreCompleteForCounty(county, locations) {
        let countyLocations = Locations.getLocationsByCounty(county);
        let notIncluded = 0;
        for (const location of countyLocations) {
            if (!locations.includes(location)) {
                notIncluded++;
            }
        }
        return notIncluded <= 0;

    }

}
