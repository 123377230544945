<template>
    <div class="custom-slider">
        <input
                ref="nightReliabilitySlider"
                type="range"
                :min="minValue"
                :max="maxValue"
                :step="stepValue"
                v-model="selectedValue"
                @input="onSliderInput"
        />
        <div class="slider-value">{{ selectedValue }} {{getSliderDesc (selectedValue)}}</div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {mapActions} from "vuex";
import {NightReliability} from "@/utils/constants";

export default {
    name: "NightReliabilityChartSlider",
    props: {
    },
    data() {
        return {
            minValue: 1,
            maxValue: 91,
            stepValue: 2,
            selectedValue: NightReliability.NIGHTS,
        };
    },
    watch: {
        selectedValue: debounce(function (newValue) {
            this.triggerAction(newValue);
        }, 400),
    },
    methods: {
        ...mapActions('forecastsStore', ['changeNightReliabilityDays']),
        onSliderInput() {
            // This method is called when the slider value changes
            // It triggers the action if the value hasn't changed for 200ms
            this.selectedValue = parseInt(this.selectedValue); // Ensure it's an integer
        },
        triggerAction(value) {
            // This function will be called after the value hasn't changed for 200ms
            // console.log('Selected value:', value);
            this.changeNightReliabilityDays(value);
            // You can perform your desired action here
        },
        getSliderDesc(value) {
            if (value == 1) {
                return " poslední noc";
            } else if (value <= 4) {
                return " poslední noci";
            } else {
                return " posledních nocí";
            }
        },
    },
};
</script>

<style scoped>
.custom-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

input[type="range"] {
    width: 100%;
}

.slider-value {
    margin-top: 4px;
    font-weight: 600;
}
</style>
