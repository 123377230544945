<template>


    <div class="row">

        <div class="col-md-4 col-sm-12">

            <div style="margin-top: 15px">
                <b-form-checkbox id="updatedTimeChBox" v-model="showUpdatedTime" name="checkbox-1" value="true" unchecked-value="false" switch style="user-select: none;">
                    Časy aktualizace
<!--                    <Tooltip text="Časy znázorněny v tabulce předpovědí formou tlustých svislých čar"-->
<!--                        placement="right">-->
<!--                    </Tooltip>-->
                </b-form-checkbox>
                <span>

                </span>
            </div>

            <div style="margin-top: 20px">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="forecast-diffs-radios" value="diff" style="margin-bottom: 3px">
                        Ø Prosté odchylky
                    </b-form-radio>
                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="forecast-diffs-radios" value="diff-abs" style="margin-bottom: 3px">
                        Ø Absolutní odchylky
                    </b-form-radio>
                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="forecast-diffs-radios" value="no-diff">
                        Nezobrazovat odchylky
                    </b-form-radio>
                </b-form-group>
            </div>

        </div>

        <div class="col-md-4 col-sm-12">

            <div v-if="getDisplayedForecastDiffType === 'diff' || getDisplayedForecastDiffType === 'no-diff'">
                <h6 style="margin-top: 20px">Skutečnost lepší o (%):</h6>
                <table style="background-color: white">
                    <tr>
                        <td v-for="shade in getShadesOfGreen()" v-bind:key="shade" :style=getShadeStyle(shade) class="color-palette">
                            {{ getShadeValue(shade, getShadesOfGreen()) }}
                        </td>
                    </tr>
                </table>

                <h6 style="margin-top: 25px">Skutečnost horší o (%):</h6>
                <table style="background-color: white">
                    <tr>
                        <td v-for="shade in getShadesOfRed()" v-bind:key="shade" :style=getShadeStyle(shade) class="color-palette">
                            {{ getShadeValue(shade, getShadesOfRed()) }}
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="getDisplayedForecastDiffType === 'diff-abs'">
                <h6 style="margin-top: 20px">Skutečnost odlišná o (%):</h6>
                <table style="background-color: white">
                    <tr>
                        <td v-for="shade in getShadesOfOrange()" v-bind:key="shade" :style=getShadeStyle(shade) class="color-palette">
                            {{ getShadeValue(shade, getShadesOfOrange()) }}
                        </td>
                    </tr>
                </table>
            </div>

            <h6 style="margin-top: 20px">
                Nelze porovnat se skutečností
                <Tooltip text="Převážně v nočních hodinách ČHMÚ měří oblačnost jen na malém počtu stanic.
        Porovnaní s předpovědí je tak v některých případech málo vypovídající nebo nemožné"></Tooltip>
                :
            </h6>

            <table style="background-color: white">
                <tr>
                    <td :style="'background-color:' + getNoComparisonColor()" class="color-palette">
                        :-(
                    </td>
                </tr>
            </table>

        </div>

        <div class="col-md-4 col-sm-12">
            <h6 style="margin-top: 20px">Tip:</h6>
            <p>
                Pro více informací o datech navštivte <router-link to="/projekt">sekci o projektu</router-link>
            </p>
        </div>

    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BFormCheckbox, BFormGroup, BFormRadio} from 'bootstrap-vue';
import {VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";
import {ForecastColors} from "@/config/forecast-colors";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'VerifiedForecastsLegend',
    mixins: [utilsMixin],
    mounted() {
        this.showUpdatedTime = this.getShowUpdateTime;
        this.selected = this.getDisplayedForecastDiffType;
    },
    props: {},
    data() {
        return {
            selected: null,
            minDiffForAccuracyStyling: 5,
            showUpdatedTime: undefined
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        showUpdatedTime: function (newValue, oldValue) {
            this.toggleShowUpdateTime(newValue);
        },
        selected: function () {
            // console.log("selected: " + this.selected)
            this.toggleDisplayedForecastDiffType(this.selected);
        }
    },
    computed: {
        ...mapGetters('verificationsStore', ['getDisplayedForecastDiffType', 'getShowUpdateTime']),
    },
    methods: {
        ...mapActions('verificationsStore', ['toggleShowUpdateTime', 'toggleDisplayedForecastDiffType']),
        getShadeStyle(shade) {
            return "text-align: center; backgroundColor: " + shade;
        },
        getShadeValue(shade, shades) {
            let index = shades.indexOf(shade);
            let ceiling = Math.ceil(100 / shades.length * (index + 1));
            if (index === 0) {
                return this.minDiffForAccuracyStyling + "..." + ceiling;
            } else {
                return "..." + ceiling;
            }
        },
        getShadesOfRed() {
            return ForecastColors.shadesOfRed;
        },
        getShadesOfGreen() {
            return ForecastColors.shadesOfGreen;
        },
        getShadesOfOrange() {
            return ForecastColors.shadesOfOrange;
        },
        getNoComparisonColor() {
            return ForecastColors.noComparisonColor;
        }
    },
    components: {
        Tooltip, BFormCheckbox, BFormRadio, BFormGroup
    }
}
</script>

<style>
table {
    border-collapse: collapse;
}

td, th {
    border: 1px solid #7e7c7c;
    width: 30px; /* Adjust the width as needed */
    text-align: center;
    font-size: 12px;
}

td.color-palette {
    color: #5e5e5e;
    font-weight: 300;
    font-size: x-small;
    border: 0;
}

</style>

