import {ApiConfig} from "@/config/api-config";

export class ApiClient {

    static getJsonRequest = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
    };

    static postJsonRequest = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
    };

    static deleteJsonRequest = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
    };

    static async fetchVerifications(counties) {
        let url = ApiConfig.verificationPageURL(counties);
        console.log(">>> "+ Date.now() + " Going to fetch verification data");
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchVerifiedForecasts(locations, daysOffsetFromToday) {
        let url = ApiConfig.verifiedForecastsPageURL(locations, daysOffsetFromToday);
        console.log(">>> "+ Date.now() + " Going to fetch verified forecast data");
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchAutocompletedLocations(searchText, countryCode) {
        let url = ApiConfig.autocompletedLocationsURL(searchText, countryCode);
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchForecasts(partialUpdate, locationName, lat, lon, countryCode, reliabilityRadius, days, nightReliabilityDays,
                                displayedSources, nightFromHour, nightToHour, reliabilityType) {
        let firstAccess = ApiClient.getFirstAccess();
        let url = ApiConfig.forecastsPageURL(partialUpdate, locationName, lat, lon, countryCode, firstAccess, reliabilityRadius,
            days, nightReliabilityDays, displayedSources, nightFromHour, nightToHour, reliabilityType);
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchForecastsByUuid(uuid, reliabilityRadius, dayCount, nightReliabilityDays,
                                      displayedSources, nightFromHour, nightToHour, reliabilityType) {
        let firstAccess = ApiClient.getFirstAccess();
        let url = ApiConfig.forecastsPageByUuidURL(uuid, firstAccess, reliabilityRadius, dayCount,
            nightReliabilityDays, displayedSources, nightFromHour, nightToHour, reliabilityType);
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchForecastsLink(locationName, lat, lon, countryCode, displayedDay) {
        let firstAccess = ApiClient.getFirstAccess();
        let url = ApiConfig.forecastsLinkURL(locationName, lat, lon, countryCode, firstAccess, displayedDay);
        return fetch(url, ApiClient.postJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async saveHomeLocation(locationName, lat, lon, countryCode) {
        let firstAccess = ApiClient.getFirstAccess();
        let url = ApiConfig.saveHomeLocationLink(locationName, lat, lon, countryCode, firstAccess);
        return fetch(url, ApiClient.postJsonRequest);
    }

    static async deleteHomeLocation() {
        let firstAccess = ApiClient.getFirstAccess();
        let url = ApiConfig.deleteHomeLocationLink(firstAccess);
        return fetch(url, ApiClient.deleteJsonRequest);
    }

    static getFirstAccess() {
        let firstAccess;
        if (!localStorage.getItem("plausible_ignore")) {
            firstAccess = localStorage.getItem("first_access");
            if (!firstAccess) {
                return "NA"
            }
            return firstAccess;
        } else {
            return "EXCLUDED";
        }
    }

    static async fetchReliabilities(locationName, lat, lon, countryCode, reliabilityRadius, reliabilityType) {
        let url = ApiConfig.forecastReliabilitiesURL(locationName, lat, lon, countryCode, reliabilityRadius, reliabilityType);
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async fetchNightReliabilities(locationName, lat, lon, reliabilityRadius, nightReliabilityDays) {
        let url = ApiConfig.forecastNightReliabilitiesURL(locationName, lat, lon, reliabilityRadius, nightReliabilityDays);
        return fetch(url, ApiClient.getJsonRequest)
            .then(data => data.json())
            .catch(error => console.log(error));
    }

    static async postAccess() {
        let firstAccess = localStorage.getItem("first_access");
        if (!firstAccess) {
            firstAccess = ApiClient.formatDate(new Date());
            localStorage.setItem("first_access", firstAccess);
        }
        if (!localStorage.getItem("plausible_ignore")) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const post = {
                method: 'POST',
                redirect: 'follow',
            }
            let url = ApiConfig.accessURL(timezone, firstAccess);
            return fetch(url, post)
                .catch(error => console.log(error));
        } else {
            // console.log("Not posting access!")
        }
    }

    static formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        const formattedDate = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}_${milliseconds}`;

        return formattedDate;
    }

}
