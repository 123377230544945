export class Constants {
    static DUMMY_ZERO = 0.9
}

export class NightReliabilityTypes {
    static CLEAR_SKY = "CLEAR_SKY"
    static CLOUDY_SKY = "CLOUDY_SKY"
    static PESSIMISTIC_MINORITY_FORECAST = "PESSIMISTIC_MINORITY_FORECAST"
}

export class NightReliability {
    static NIGHTS = 31;
}
