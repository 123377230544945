<template>

    <div class="container page-container">

        <h2 style="padding-top: 30px;text-align: center">O projektu a datech</h2>
        <hr>

        <p>Tato stránka je nekomereční projekt, který se zaměřuje na agregaci předpovědí oblačnosti různých modelů a vyhodnocování jejich přesnosti v reálném čase.
        Má sloužit především astronomům amatérům, pro lepší orientaci v často rozporuplných předpovědích.</p>

        <h5 class="small-header">Jak se s daty pracuje?</h5>

        <p>Data předpovědí pro uživatelem vyhledanou lokalitu jsou zpravila získávana v daný moment na základě souřadnic daného místa.
            Data se modelů určená pro vyhodnocení jejich přesnosti jsou stahována pětkrát denně ve 4 až 5 hodinových intervalech.
            Každou hodinu se aktualizují data o následném reálném naměřeném stavu oblačnosti a ta se srovnávají s předpovědí jednotlivých modelů a určuje se jich aktuální přesnost.
            Přesnost je na webu vizualizována různými způsoby dle kontextu.
        </p>

        <p>Měření stavu oblačnosti se aktualizuje z <a target=”_blank” href="https://www.chmi.cz/aktualni-situace/aktualni-stav-pocasi/ceska-republika/stanice/profesionalni-stanice/tabulky/oblacnost-dohlednost">webu ČHMÚ</a>
            , který měření provádí a v reálném čase publikuje pro 40 lokalit v ČR. Z předpovědi a změřeného stavu pro konkrétní hodinu a místo je vypočítávána odchyka.
            Ta může být následně průměrována za větší územní celky a delší časové úseky.
        </p>

        <p>
            V nočních hodinách je množství měření bohužel značně omezené a probíhá zpravidla pouze pro zhruba deset stanic
            v celé republice. Pozdní noční předpovědi jsou proto často bez ověření.
        </p>

        <p>Omezený počet lokalit s měřením obecně limituje možnosti srovnávání v rámci tohoto projektu.
            Přesto se zdá být užitečné agregovat data z více oblastí, a získat tak představu o celkové aktuální přesnosti jednotlivých předpovědí.</p>


        <h5 class="small-header">Jaké jsou zdroje dat?</h5>

        <p>Zdroje dat měření:</p>

        <ul>
            <li><a href="https://www.chmi.cz/aktualni-situace/aktualni-stav-pocasi/ceska-republika/stanice/profesionalni-stanice/tabulky/oblacnost-dohlednost"
                   target=”_blank” class="source">
                ČHMÚ</a> - hodinová měření stavu celkové oblačnosti jsou k dispozici pro 40 lokalit v ČR
            </li>
        </ul>

        <p>Zdroje dat předpovědí a jejich vztah k aplikacím a webům o počasí:</p>

        <ul>
            <li><a @click="copyLink($event)" href="https://developer.accuweather.com/" class="source" target=”_blank”>
                AccuWeather</a> - stejnojmenná aplikace a web
            </li>
            <li><a href="https://www.chmi.cz/files/portal/docs/meteo/ov/aladin/results/public/meteogramy/mhtml/m.html" class="source" target=”_blank”>
                Aladin</a> - stejnojmenná aplikace a také web s meteogramy ČHMÚ
            </li>
            <li><a href="https://open-meteo.com/en/docs/ecmwf-api" class="source" target=”_blank”>
                ECMWF</a> - populární model používaný mimo jiné ve výchozím zobrazení aplikace a webu <a href="https://windy.com" target=”_blank”>Windy</a>
            </li>
            <li><a href="https://open-meteo.com/en/docs/gfs-api" class="source" target=”_blank”>
                GFS</a> - populární americký model dostupný v některých aplikacích, např. <a href="https://windy.com" target=”_blank”>Windy</a>
            </li>
            <li><a href="https://open-meteo.com/en/docs/dwd-api" class="source" target=”_blank”>
                ICON D2</a> - středoevropský model německé DWD
            </li>
            <li><a href="https://docs.api.met.no/doc/" class="source" target=”_blank”>
                MET Norway</a> - Norský meteorologický institut je zdrojem dat pro populární aplikace a weby
                <a href="https://www.yr.no/en" target=”_blank”>Yr</a>,
                <a href="https://play.google.com/store/apps/details?id=org.androworks.klara&hl=cs&gl=US" target=”_blank”>Klara</a> a
                <a href="https://clearoutside.com" target=”_blank”>Clear Outside</a>
            </li>
            <li><a @click="copyLink($event)" href="https://meteoblue.com" class="source" target=”_blank”>
                Meteoblue</a> - stejnojmenná aplikace a web
            </li>
            <li><a href="https://www.meteosource.com/" class="source" target=”_blank”>
                MeteoSource</a> - zdroj dat pro hlavní předpověď aplikace a webu <a href="https://clearoutside.com" target=”_blank”>Clear Outside</a>
            </li>
        </ul>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: "About",
    mounted () {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showTooltip: false // workaround for clicked locations to lose focus and the tooltip to be hidden
        }
    },
    computed: {
        ...mapGetters([])
    },
    methods: {
        copyLink(event) {
            event.preventDefault();
            // const link = event.target.href;
            // navigator.clipboard.writeText(link)
            //     .then(() => {
            //         console.log('Link copied to clipboard');
            //         const tooltip = this.$refs.tooltip;
            //         const linkRect = event.target.getBoundingClientRect();
            //         tooltip.style.opacity = 1;
            //         tooltip.style.top = linkRect.top + 'px';
            //         tooltip.style.left = linkRect.right + '10px';
            //         setTimeout(() => {
            //             tooltip.style.opacity = 0;
            //         }, 2000); // hide after 2 seconds
            //     })
            //     .catch((error) => {
            //         console.error('Failed to copy link', error);
            //     });
        },
    },
    // eslint-disable-next-line vue/no-unused-components
    components: {Tooltip}
}

</script>

<style scoped>
.small-header {
    padding-top: 10px;
    text-align: center
}

.source {
    font-weight: 600;
}
</style>
