<script>
import {Line} from "vue-chartjs";
import {reactiveProp} from "vue-chartjs/es/mixins";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
    name: "LineChart",
    components: {
        ChartJsPluginDataLabels
    },
    extends: Line,
    mixins: [reactiveProp],
    props: {
        // Important: chartData are provided by the reactiveProp
        // chartData: null,
        chartOptions: null
    },
    mounted () {
        console.log("Mounting LineChart")
        this.addPlugin(ChartJsPluginDataLabels);
        this.renderChart(this.chartData, this.chartOptions);
    }
}
</script>

<style scoped>

</style>
