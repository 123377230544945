import {ApiConfig} from "@/config/api-config";

const state = {
    cachedRealtimeSunImage: null,
}

const getters = {
    getCachedRealtimeSunImage(state) {
        return state.cachedRealtimeSunImage;
    },
}


const mutations = {
    setCachedRealtimeSunImage(state, resource) {
        state.cachedRealtimeSunImage = resource;
    },
}


const actions = {
    // eslint-disable-next-line no-unused-vars
    async preloadResources({commit, getters, dispatch}) {
        dispatch('preloadRealtimeSunImage');
    },

    async preloadRealtimeSunImage({ commit }) {
        try {
            const response = await fetch(ApiConfig.sunAnimationUrl());
            const data = await response.blob(); // or response.json() depending on resource type
            commit('setCachedRealtimeSunImage', data);
            console.log("Preloaded realtime sun image")
        } catch (error) {
            console.error('Failed to preload resource', error);
        }
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
