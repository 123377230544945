<template>

    <div>
        <b-navbar class="fixed-top" toggleable="lg" type="dark" variant="primary">
            <b-navbar-brand to="/">SkyAccuracy.cz</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/predpovedi" class="item-padding">Předpovědi</b-nav-item>
                    <b-nav-item to="/spolehlivost" class="item-padding">Spolehlivost</b-nav-item>
                    <b-nav-item to="/projekt" class="item-padding">O projektu</b-nav-item>
                    <b-nav-item to="/aktuality" class="item-padding">Aktuality</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {narrowViewPortMixin} from "@/components/reusables/narrow-view-port-mixin";
import {
    BNavbar,
    BNavbarNav,
    BNavbarBrand,
    BNavbarToggle,
    BNavItemDropdown,
    BNavItem,
    BCollapse,
    BDropdownItem
} from 'bootstrap-vue';

export default {
    name: 'NavBar',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        BNavbar, BNavbarNav, BNavbarBrand, BNavbarToggle, BNavItemDropdown, BNavItem, BCollapse, BDropdownItem
    },
    mixins: [narrowViewPortMixin],
    computed: {
        ...mapGetters([]),
    },
    methods: {
        ...mapActions([]),
    },

}
</script>


<style>

.item-padding {
    padding-left: 10px;
}

</style>


