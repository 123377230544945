<template>
    <div>

        <b-button size="sm" @click="togglePopup" variant="primary">
            <SettingsIcon name="settings"></SettingsIcon>
            Délka noci
        </b-button>

        <div class="overlay" v-if="popupDisplayed" @click="togglePopup"></div>

        <Transition>
            <div v-if="popupDisplayed" class="popup">
                <div class="ml-auto mr-3" v-on:click="togglePopup">
                    <span class="popup-close">&times;</span>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                    <h4 class="popup-title">Jaká část noci vás zajímá?</h4>
                    <hr/>
                    <div class="row">
                        <p class="popup-text">
                            Nastavením určíte, za jakou část noci bude generována souhrnná ikonka pro
                            noc konkrétního data a také vyhodnocován vývoj změn předpovědí počasí.
                        </p>
                    </div>

                    <div class="row d-flex align-items-center">
                        <div class="col-sm-12">
                            <b-form-select v-model="startTimeValue" :options="getFromOptions()" class="w-auto"></b-form-select>
                             -
                            <b-form-select v-model="endTimeValue" :options="getToOptions()" class="w-auto"></b-form-select>
                        </div>
                    </div>
                    <b-button style="margin-top: 40px" @click="applyRange" variant="primary" >
                        Nastavit
                    </b-button>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {BButton, BFormSelect} from "bootstrap-vue";
import {Utils} from "@/utils/Utils";
import SettingsIcon from "@/components/forecast/SettingsIcon.vue";

export default {
    name: "NightSettingsPopup",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastStatus', 'getForecastTrend',
            'getForecastStatusType', 'getPageTitle', 'todayForecastDisplayed', 'getNightFromHour', 'getNightToHour']),
    },
    data() {
        return {
            popupDisplayed: false,
            startTimeValue: null,
            endTimeValue: null,
            timeOptions: [
                { value: 0, hour: 17, from: true, to: false, text: "17:00" },
                { value: 1, hour: 18, from: true, to: false, text: "18:00" },
                { value: 2, hour: 19, from: true, to: true, text: "19:00" },
                { value: 3, hour: 20, from: true, to: true, text: "20:00" },
                { value: 4, hour: 21, from: true, to: true, text: "21:00" },
                { value: 5, hour: 22, from: true, to: true, text: "22:00" },
                { value: 6, hour: 23, from: true, to: true, text: "23:00" },
                { value: 7, hour: 0, from: true, to: true, text: "00:00" },
                { value: 8, hour: 1, from: true, to: true, text: "01:00" },
                { value: 9, hour: 2, from: true, to: true, text: "02:00" },
                { value: 10, hour: 3, from: true, to: true, text: "03:00" },
                { value: 11, hour: 4, from: true, to: true, text: "04:00" },
                { value: 12, hour: 5, from: true, to: true, text: "05:00" },
                { value: 13, hour: 6, from: false, to: true, text: "06:00" },
                { value: 14, hour: 7, from: false, to: true, text: "07:00" },
            ],
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['reloadForecasts', 'changeNightFromHour', 'changeNightToHour']),
        togglePopup() {
            this.popupDisplayed = !this.popupDisplayed;
            if (this.popupDisplayed) {
                if (this.getNightFromHour !== null && this.getNightToHour !== null) {
                    this.startTimeValue = this.timeOptions.find(v => v.hour == this.getNightFromHour).value;
                    this.endTimeValue = this.timeOptions.find(v => v.hour == this.getNightToHour).value;
                }
            } else {
                this.$emit('nightSettingsPopupClosed', 'Hello from the child!');
            }
        },
        getDate() {
            return Utils.formatDate(new Date(this.getForecastTrend.date), true, false, true);
        },
        applyRange() {
            // Handle the selected start and end times
            // console.log("Selected Start Time:", this.startTimeValue);
            // console.log("Selected End Time:", this.endTimeValue);
            if (this.startTimeValue != null && this.endTimeValue != null) {
                this.changeNightFromHour(this.timeOptions[this.startTimeValue].hour);
                this.changeNightToHour(this.timeOptions[this.endTimeValue].hour);
                this.togglePopup();
                this.reloadForecasts();
            } else {
                this.togglePopup();
            }
        },
        getFromOptions() {
            return this.timeOptions.filter(v => v.from === true && (this.endTimeValue === null || v.value < this.endTimeValue));
        },
        getToOptions() {
            return this.timeOptions.filter(v => v.to === true && (this.startTimeValue === null || v.value > this.startTimeValue));
        },
    },
    components: {
        SettingsIcon,
        BFormSelect,
        BButton,
    }
}
</script>

<style scoped>

.w-auto {
    width: auto;
}

</style>
