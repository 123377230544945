<template>

    <div class="container" style="max-width: 900px; padding-left: 40px; padding-right: 40px; ; padding-bottom: 40px; background-color: white; margin-top: 95px;">

        <h2 style="padding-top: 30px;text-align: center">Podmínky užívání</h2>
        <hr>

        <p>Převzetí obsahu webu nebo šíření je zakázáno.</p>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "TermsAndConditions",
    computed: {
        ...mapGetters([])
    },
    methods: {
    },
    components: {}
}

</script>
