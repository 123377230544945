<template>
    <transition name="fade">
        <div v-if="popupDisplayed" class="overlay" @click="closePopup">
            <div class="news-popup" @click.stop>
                <div class="popup-content">
                    <div class="popup-header">
                        <h4 class="popup-title">Máme pro vás novinky! &#129395;</h4>
                        <span class="popup-close" @click="closePopup">&times;</span>
                    </div>
                    <div class="popup-body">
                        <p>Na webu je pár nových věcí, které by Vás mohly zajímat. Jednou z nich jsou výstrahy před nepřízní počasí.</p>
                        <p>Pokud vás zajímají další novinky, navštivte
                            <router-link to="/aktuality">sekci aktualit</router-link>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import { Version } from "@/version";

export default {
    name: "NewsPopup",
    computed: {
        ...mapGetters("forecastsStore", ["isLoading"]),
    },
    data() {
        return {
            popupDisplayed: false,
            newsMessage: "This is your latest news update!",
        };
    },
    watch: {
        isLoading: function (inProgress) {
            // console.log("inProgress: " + inProgress)
            if (!inProgress) {
                console.log("Finished loading ..")
                setTimeout(() => {
                    const first_access = localStorage.getItem("first_access");
                    if (first_access) {
                        let returningUser;
                        if (first_access === "EXCLUDED") {
                            returningUser = true;
                        } else {
                            const parsedDate = new Date(first_access.substr(0, 10));
                            const thirtyDaysAgo = new Date();
                            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
                            returningUser = parsedDate < thirtyDaysAgo;
                        }

                        const lastDisplayedNewsVersion = localStorage.getItem(
                            "last_displayed_news"
                        );
                        const versionNewsAlreadyDisplayed =
                            Version.CURRENT === lastDisplayedNewsVersion;
                        if (!this.isLoading && !versionNewsAlreadyDisplayed && returningUser) {
                            localStorage.setItem("last_displayed_news", Version.CURRENT);
                            this.popupDisplayed = true;
                        } else {
                            console.log("Skipping news update");
                        }
                    } else {
                        console.log("Not access yet");
                    }
                }, 2000);
            }
        },
    },
    mounted() {

    },
    methods: {
        closePopup() {
            this.popupDisplayed = false;
        },
        handleBeforeLeave(to, from, next) {
            // console.log("Leaving current page to:", to.path);
            this.popupDisplayed = false;
            next();
        },
    },
};
</script>

<style scoped>
/* Overlay to cover the entire viewport */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Popup container */
.news-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1001;
    max-width: 90%;
    min-width: 330px;
    box-sizing: border-box;
    text-align: left;
}

.popup-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Popup header styling */
.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.popup-title {
    font-size: 1.3em;
    margin: 0;
    color: #333333;
}

/* Close button styling */
.popup-close {
    font-size: 1.7em;
    cursor: pointer;
    color: #888888;
    transition: color 0.3s ease;
}

.popup-close:hover {
    color: #ff5c5c;
}

/* Popup body styling */
.popup-body {
    margin-top: 15px;
    line-height: 1.6;
    color: #555555;
}

.popup-body p {
    margin: 0 0 10px;
}

.router-link {
    color: #1e90ff;
    font-weight: bold;
}

/* Media queries for responsive design */
@media (max-width: 370px) {
    .news-popup {
        width: 90%;
        font-size: 13px;
    }

    .popup-title {
        font-size: 1.1em;
    }
}

@media (min-width: 371px) and (max-width: 699px) {
    .news-popup {
        width: 350px;
        font-size: 14px;
    }

    .popup-title {
        font-size: 1.2em;
    }
}

@media (min-width: 700px) {
    .news-popup {
        width: 500px;
        font-size: 15px;
    }

    .popup-title {
        font-size: 1.3em;
    }
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
