<template>
        <div class="row" style="margin-bottom: 25px">
            <div class="col-md-2 col-sm-0"/>
            <div class="col-md-8 col-sm-12">
                <div class="row d-flex justify-content-center">
                    <ForecastStatusPopup class="status"></ForecastStatusPopup>
                    <ForecastWarningStatus class="status"></ForecastWarningStatus>
                </div>
            </div>
            <div class="col-md-2 col-sm-0"/>
        </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ForecastWarningStatus from "@/components/forecast/ForecastWarningStatus.vue";
import ForecastStatusPopup from "@/components/forecast/ForecastStatusPopup.vue";

export default {
    name: "ForecastStatuses",
    computed: {
        ...mapGetters('forecastsStore', []),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
    },
    components: {
        ForecastStatusPopup,
        ForecastWarningStatus,
    }
}
</script>

<style scoped>

.status {
    margin-left: 4px;
    margin-right: 4px;
}

</style>
