<template>

    <div v-if="showMoon" class="overlay-moon-image-parent">
        <div class="moon" :class="{paused: isPaused}" :style="cssVars">
            <div class="disc" :style="animationPaused"></div>
        </div>
        <img class="overlay-moon-image" :src="require('@/assets/images/moon_full_original.jpg')" alt="Moon"/>
    </div>
    <!--    following is workaround needed for the above to be possible to diaplay with delay-->
    <div v-else class="overlay-moon-image-parent">
        <div class="moon" style="background-color: #505050">
            <div class="disc"></div>
        </div>
        <img class="overlay-moon-image" :src="require('@/assets/images/moon_full_original.jpg')" alt="Moon"/>
    </div>

</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";

export default {
    name: "Moon",
    computed: {
        ...mapGetters('forecastsStore', []),
        cssVars: function () { // this is inspired byt this: https://www.telerik.com/blogs/passing-variables-to-css-on-a-vue-component
            return {
                '--background-color': this.backgroundColor,
                '--flip-rotate': this.flipRotate,
            }
        },
    },
    props: {
        illuminated: null, // seeing/transparency/seeing_range/transparency_range
        phase: null
    },
    mounted() {
        // the delay is an experiment in having the animation
        // run smoothly and reaching the precise intended position
        setTimeout(() => {
            this.showMoon = true; // Show the moon after delay for the animation to run smootly and timely
            this.setup();
        }, 500);
    },
    watch: {},
    data() {
        return {
            showMoon: false,
            animationPaused: '', // Initially, the animation is active
            isPaused: true, // Initially, the animation is active
            backgroundColor: '#505050',
            flipRotate: 'rotateY(0deg)',
            illiminationTimeouts: [
                {"illu": 1, "timeout": 0},
                {"illu": 5, "timeout": 150},
                {"illu": 10, "timeout": 200},
                {"illu": 15, "timeout": 280},
                {"illu": 20, "timeout": 430},
                {"illu": 25, "timeout": 490},
                {"illu": 30, "timeout": 560},
                {"illu": 35, "timeout": 600},
                {"illu": 37, "timeout": 630},
                {"illu": 40, "timeout": 680},
                {"illu": 45, "timeout": 700},
                {"illu": 48, "timeout": 720},
                {"illu": 50, "timeout": 740},
                {"illu": 52, "timeout": 770},
                {"illu": 55, "timeout": 790},
                {"illu": 60, "timeout": 850},
                {"illu": 65, "timeout": 900},
                {"illu": 70, "timeout": 950},
                {"illu": 75, "timeout": 1000},
                {"illu": 80, "timeout": 1050},
                {"illu": 85, "timeout": 1100},
                {"illu": 90, "timeout": 1150},
                {"illu": 95, "timeout": 1200},
                {"illu": 97, "timeout": 1300},
                {"illu": 99, "timeout": 1350},
                {"illu": 100, "timeout": 1450},
            ]
        };
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        setup() {
            // rotate if waning
            if (this.phase === 'WAXING') {
                this.flipRotate = 'rotateY(0deg)';
            } else if (this.phase === 'WANING') {
                this.flipRotate = 'rotateY(180deg)';
            }

            const illuminated = this.illuminated;
            // this.phase === 'WAXING'
            // const illuminated = 40;
            const illuTimeout = this.illiminationTimeouts.find(it => illuminated <= it.illu);
            const timeout = illuTimeout.timeout;

            // console.log("Timeout = " + timeout)

            // Pause the animation after 5 seconds (adjust as needed)
            setTimeout(() => {
                this.animationPaused = 'animation-play-state: paused';
                this.isPaused = true;

                if (this.phase === 'FULL') {
                    this.backgroundColor = '#e1e1d3';
                } else {
                    this.backgroundColor = '#505050';
                }
            }, timeout);
        }
    },
    components: {}
}
</script>

<style scoped>

.paused::before {
    animation-play-state: paused;
}

.paused {
    animation-play-state: paused;
}

.moon {
    width: 100%;
    height: 100%;
    border: 0.5px solid #919191;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background-color: #e1e1d3;
    /*transform: translateZ(0);*/
    transform: var(--flip-rotate); /* Flip horizontally */
}

.moon::before {
     content: " ";
     display: block;
     position: absolute;
     top: 0; left: 0;
     background-color: var(--background-color);
     /*background-color: #505050;*/
     width: 50%; height: 100%;
 }

.disc {
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    -moz-animation: rotate 3s linear infinite;
    animation: rotate 3s linear infinite;
}

.disc::before, .disc::after {
                content: " ";
                display: block;
                transform-style: preserve-3d;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                transition: transform 4s;
                position: absolute;
                backface-visibility: hidden;
}
.disc::before {
     background-color: #505050;
 }
.disc::after {
     background-color: #e1e1d3;
     transform: rotateY(180deg);
 }

@keyframes rotate {
    0%   { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
}

@keyframes flip {
    0%   { left: 0; }
    100% { left: 100%; }
}

html, body {
    height: 100%;
    width: 100%;
}

body {
    background-color: #505050;
    text-align: center;
    color: #ffffff;
    font-family: 'Fira Mono', monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: .1em;
}


.overlay-moon-image-parent {
    position: relative; /* Make the parent element relative */
}

.overlay-moon-image {
    position: absolute; /* Position the image absolutely within the parent */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3; /* Set opacity to make it transparent */
    pointer-events: none; /* Allow interactions with the content below */
}


</style>
