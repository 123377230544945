<template>
    <div>
        <div class="no-data small-warning-text" v-if="getNightReliabilityValues(this.chartType).length === 0">
            {{ getNoDataText() }}
        </div>
        <canvas :class="{ 'element-hidden': getNightReliabilityValues(this.chartType).length === 0 }" ref="nightReliabilityCanvas" style="max-width: 100%;"></canvas>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapGetters} from "vuex";
import Chart from 'chart.js'
import {ForecastColors} from "@/config/forecast-colors";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import {reactiveProp} from "vue-chartjs/es/mixins";
import {Constants, NightReliabilityTypes} from "@/utils/constants";

export default {
    name: "NightReliabilityChart",
    props: {
        chartType: null // clear night or cloudy night
    },
    computed: {
        ...mapGetters('forecastsStore', ['getNightReliabilityValues', 'getNightReliabilityTitles',
        'getClearNightReliabilityValues', 'getCloudyNightReliabilityValues',  "getPessimisticForecastReliabilityValues",
            'getClearNightReliabilityTitles', 'getCloudyNightReliabilityTitles', "getPessimisticForecastReliabilityTitles"]),
    },
    watch: {
        getClearNightReliabilityValues: function () {
            if (NightReliabilityTypes.CLEAR_SKY === this.chartType) {
                // console.log("getClearNightReliabilityValues changed")
                this.refreshData();
            }
        },
        getCloudyNightReliabilityValues: function () {
            if (NightReliabilityTypes.CLOUDY_SKY === this.chartType) {
                // console.log("getCloudyNightReliabilityValues changed")
                this.refreshData();
            }
        },
        getPessimisticForecastReliabilityValues: function () {
            if (NightReliabilityTypes.PESSIMISTIC_MINORITY_FORECAST === this.chartType) {
                // console.log("getCloudyNightReliabilityValues changed")
                this.refreshData();
            }
        },
        getClearNightReliabilityTitles: function () {
            if (NightReliabilityTypes.CLEAR_SKY === this.chartType) {
                // console.log("getClearNightReliabilityTitles changed")
                this.refreshData();
            }
        },
        getCloudyNightReliabilityTitles: function () {
            if (NightReliabilityTypes.CLOUDY_SKY === this.chartType) {
                // console.log("getCloudyNightReliabilityTitles changed")
                this.refreshData();
            }
        },
        getPessimisticForecastReliabilityTitles: function () {
            if (NightReliabilityTypes.PESSIMISTIC_MINORITY_FORECAST === this.chartType) {
                // console.log("getClearNightReliabilityTitles changed")
                this.refreshData();
            }
        },
    },
    data() {
        return {
            loaded: false,
            chart: null,
            datacollection: null,
            options: {
                layout: {
                    padding: {
                        left: 5,
                        right: 25,
                        top: 30,
                        bottom: 5
                    },
                },
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.7,
                legend: {
                    display: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                        },
                        // gridLines: {
                        //     zeroLineWidth:1.5,
                        //     zeroLineColor: 'rgb(0,0,0)'
                        // },
                        gridLines: {
                            display: false, // Set this to false to remove horizontal grid lines
                        },
                        ticks: {
                            fontColor: 'rgb(77,77,77)',
                            fontSize: 11,
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            fontColor: 'rgb(5,58,140)',
                            fontSize: 13,
                            labelString: 'Úspěšnost %'
                        },
                        beginAtZero: true,
                        // gridLines: {
                        //     zeroLineWidth:1.5,
                        //     zeroLineColor: 'rgb(0,0,0)'
                        // },
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 100,
                            maxTicksLimit: 6,
                            fontColor: 'rgb(77,77,77)',
                            padding: 4
                        },
                        gridLines: {
                            // zeroLineWidth:1.5,
                            zeroLineColor: 'rgba(0, 0, 0, 0)'
                        },
                    }]
                },
                tooltips: {
                    enabled: false,
                },
                plugins: {
                    datalabels: {
                        font: {
                            size: 9,
                            // weight: 700
                        },
                        // color: 'rgb(100,163,229)',
                        anchor: 'end', // Adjust anchor to move labels to the end (top) of bars
                        align: 'end',  // Adjust alignment to move labels to the end (top) of bars
                        // eslint-disable-next-line no-unused-vars
                        formatter: function(value, context) {
                            return value === Constants.DUMMY_ZERO ? 0 : value;
                        },
                    },
                },
            }
        }
    },
    methods: {
        refreshData() {
            const colors = this.getNightReliabilityValues(this.chartType).map(reliability => {
                var idx = Math.floor(reliability / 10);
                idx = idx === 10 ? 9 : idx;
                return ForecastColors.halfTransparentShadesOfReliability[idx];
            });

            this.datacollection = {
                labels: this.getNightReliabilityTitles(this.chartType),
                datasets: [{
                    data: this.getNightReliabilityValues(this.chartType),
                    backgroundColor: colors,
                    // borderColor: 'rgb(24,89,133)',
                    borderWidth: 1,
                    datalabels: {
                        // color: 'rgb(0,0,0)',
                        display: true
                    },
                }]
            };

            // this.options.responsive = window.innerWidth > 768;
            this.loaded = true;
            this.createCustomChart();
        },
        createCustomChart() {
            if (this.chart) {
                this.chart.destroy(); // Destroy the previous chart if it exists
            }

            const ctx = this.$refs.nightReliabilityCanvas.getContext("2d");
            this.chart = new Chart(ctx, {
                type: 'bar',
                mixins: [reactiveProp],
                data: this.datacollection,
                options: this.options,
                plugins: [ChartJsPluginDataLabels]
            });
        },
        getNoDataText() {
            if (NightReliabilityTypes.PESSIMISTIC_MINORITY_FORECAST === this.chartType) {
                return `Žádná data - situace v posledních dnech nenastala`
            } else {
                return `Žádná data - nikdo nepředpovídal ${NightReliabilityTypes.CLEAR_SKY === this.chartType ? "jasnou" : "oblačnou"} noční oblohu`
            }
        },
    },
    mounted() {
        console.log("Mounting NightReliabilityChart ... ");
        this.refreshData();
    },
    components: {
    }
}
</script>

<style scoped>
    .no-data {
        margin-top: 15px;
        margin-bottom: -15px;
        font-size: 10px; /* Adjust the font size as needed */
        text-align: center;
    }
    .element-hidden {
        display: none; /* or visibility: hidden; */
    }
</style>
