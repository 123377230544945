<template>
    <!-- Footer -->
    <footer class="mt-5 pb-4 footer-bg-color">
        <div class="width-content pt-3 py-4 container-fluid d-flex flex-column flex-md-row justify-content-between">

            <div class="text-left d-flex flex-column w-100 mt-3 mt-md-0 p-3">
                <h5 class="h4 font-weight-bold footer-headline-color">Kontakty</h5>
                <p class="text-white" style="font-size: 14px">Nápady na zlepšení a připomínky můžete zasílat na:</p>
                <p class="text-white">skyaccuracy@gmail.com</p>
            </div>

            <div class="text-left d-flex flex-column w-100 mt-3 mt-md-0 text-white p-3">
                <h5 class="h4 font-weight-bold footer-headline-color">Atribuce</h5>
                <a href="https://www.visualcrossing.com/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Weather Data Provided by Visual Crossing</a>
                <a href="https://open-meteo.com/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Weather Data by Open-Meteo.com</a>
                <a href="https://api.met.no/weatherapi/locationforecast/2.0/documentation/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Data from MET Norway</a>
                <a href="https://www.meteosource.com/cs/api-predpoved-pocasi/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Powered by MeteoSource API</a>
                <a href="https://content.meteoblue.com/en/business-solutions/weather-apis" class="footer-link mb-1" target=”_blank” rel="noreferrer">Powered by Meteoblue API</a>
                <a href="http://www.accuweather.com/" class="footer-link mb-1" target=”_blank” rel="noreferrer">
                    Powered by
                    <img style="height: 12px; width: 84px" src="../assets/logos/accuweather/JPEG (white background)/Horizontal/AW_REGISTERED_Horiz_JPEG_Logo.jpg" alt="image">
                </a>
                <a href="https://www.geoapify.com/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Powered by  Geoapify</a>
                <a href="https://sunrise-sunset.org/" class="footer-link mb-1" target=”_blank” rel="noreferrer">Powered by Sunrise Sunset API</a>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>



<style>

.footer-bg-color {
    background-color: #242424;
}

.footer-headline-color {
    color: #9c9c9c;
}

</style>
