import Vuex from 'vuex';
import Vue from 'vue';
import verificationsStore from "@/store/modules/verifications-store";
import forecastsStore from "@/store/modules/forecasts-store";
import settingsStore from "@/store/modules/settings-store";
import staticResourcesStore from "@/store/modules/static-resources-store";

Vue.use(Vuex); // connection between Vue and Vuex

export default new Vuex.Store({
    // here we basically register all our modules for storing different types of data
    modules: {
        verificationsStore: verificationsStore,
        forecastsStore: forecastsStore,
        settingsStore: settingsStore,
        staticResourcesStore: staticResourcesStore
    },
});
