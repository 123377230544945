<template>
    <div class="night night-icon-size">
        <div v-if="show1" class="star" style="top: 45%; left: 59%; width: 1.5px; height: 1.5px;"></div>
        <div v-if="show2" class="star" style="top: 70%; left: 60%; width: 1.3px; height: 1.3px;"></div>
        <div v-if="show3" class="star" style="top: 43%; left: 84%; width: 1px; height: 1px;"></div>
        <div v-if="show3" class="star" style="top: 40%; left: 30%; width: 1px; height: 1px;"></div>
        <div v-if="show3" class="star" style="top: 70%; left: 23%; width: 1px; height: 1px;"></div>
        <div v-if="show4" class="star" style="top: 10%; left: 50%; width: 0.7px; height: 0.7px;"></div>
        <div v-if="show4" class="star" style="top: 55%; left: 40%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show4" class="star" style="top: 33%; left: 34%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show5" class="star" style="top: 15%; left: 28%; width: 0.7px; height: 0.7px;"></div>
        <div v-if="show5" class="star" style="top: 50%; left: 20%; width: 0.7px; height: 0.7px;"></div>
        <div v-if="show5" class="star" style="top: 65%; left: 70%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show6" class="star" style="top: 20%; left: 67%; width: 1.0px; height: 1.0px;"></div>
        <div v-if="show6" class="star" style="top: 44%; left: 50%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show6" class="star" style="top: 75%; left: 27%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 86%; left: 50%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 56%; left: 50%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 67%; left: 72%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 46%; left: 36%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 34%; left: 47%; width: 0.8px; height: 0.8px;"></div>
        <div v-if="show7" class="star" style="top: 24%; left: 56%; width: 0.8px; height: 0.8px;"></div>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";

export default {
    name: "Night",
    mounted() {
        setTimeout(() => {this.show1 = true;}, 400);
        setTimeout(() => {this.show2 = true;}, 600);
        setTimeout(() => {this.show3 = true;}, 800);
        setTimeout(() => {this.show4 = true;}, 1000);
        setTimeout(() => {this.show5 = true;}, 1200);
        setTimeout(() => {this.show6 = true;}, 1400);
        setTimeout(() => {this.show7 = true;}, 1700);
    },
    computed: {
        ...mapGetters('forecastsStore', []),
    },
    props: {
    },
    watch: {},
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            show7: false,
        };
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
    },
    components: {}
}
</script>

<style scoped>


.night {
    position: relative;
    border-radius: 50%;
    /*margin-left: 15px;*/
    animation: skyTransition 2s ease-in-out forwards;
}

@keyframes skyTransition {
    0% { background: radial-gradient(circle, #4682B4 55%, #a9cef5 100%);}
    5% { background: radial-gradient(circle, #457FB2 55%, #a0c3ea 100%);}
    10% { background: radial-gradient(circle, #437CAE 55%, #96bde7 100%);}
    15% { background: radial-gradient(circle, #417AAA 55%, #a6ccf3 100%);}
    20% { background: radial-gradient(circle, #3F77A6 55%, #7CAAD9 100%);}
    25% { background: radial-gradient(circle, #3D74A2 55%, #78A2D4 100%);}
    30% { background: radial-gradient(circle, #3A709E 55%, #7599D0 100%);}
    35% { background: radial-gradient(circle, #376C99 55%, #718FCC 100%);}
    40% { background: radial-gradient(circle, #346995 55%, #6D86C7 100%);}
    45% { background: radial-gradient(circle, #316590 55%, #697DC3 100%);}
    50% { background: radial-gradient(circle, #2E608B 55%, #6573BF 100%);}
    55% { background: radial-gradient(circle, #2B5C86 55%, #616ABA 100%);}
    60% { background: radial-gradient(circle, #285882 30%, #5C60B5 100%);}
    65% { background: radial-gradient(circle, #24537D 30%, #5757B0 100%);}
    70% { background: radial-gradient(circle, #214E78 30%, #524DAC 100%);}
    75% { background: radial-gradient(circle, #1E4973 30%, #4D43A7 100%);}
    80% { background: radial-gradient(circle, #1A446E 30%, #4839A2 100%);}
    85% { background: radial-gradient(circle, #163F69 30%, #432F9D 100%);}
    90% { background: radial-gradient(circle, #0b335d 30%, #3E2598 100%);}
    95% { background: radial-gradient(circle, #072341 10%, #391B93 100%);}
    100% { background: radial-gradient(circle, #00031e 10%, #0614be 100%);}
}

.star {
    content: '';
    position: absolute;
    background-color: #fff; /* Star color */
    border-radius: 50%;
}

</style>
