export class MoonPhases {

    static getImage(illuminated, phase) {
        // console.log("illuminated = " + illuminated + ", phase = " + phase);
        if (illuminated >= 97) {
            if (phase === 'WAXING') return 'moon_waxing_14.png';
            if (phase === 'WANING') return 'moon_waning_16.png';
            return 'moon_full_15.png';
        } else if (illuminated >= 92) {
            if (phase === 'WAXING') return 'moon_waxing_13.png';
            if (phase === 'WANING') return 'moon_waning_16.png';
            return 'moon_waxing_13.png';
        } else if (illuminated >= 88) {
            if (phase === 'WAXING') return 'moon_waxing_12.png';
            if (phase === 'WANING') return 'moon_waning_17.png';
            return 'moon_waxing_12.png';
        } else if (illuminated >= 85) {
            if (phase === 'WAXING') return 'moon_waxing_11.png';
            if (phase === 'WANING') return 'moon_waning_18.png';
            return 'moon_waxing_11.png';
        } else if (illuminated >= 76) {
            if (phase === 'WAXING') return 'moon_waxing_10.png';
            if (phase === 'WANING') return 'moon_waning_19.png';
            return 'moon_waxing_10.png';
        } else if (illuminated >= 65) {
            if (phase === 'WAXING') return 'moon_waxing_9.png';
            if (phase === 'WANING') return 'moon_waning_20.png';
            return 'moon_waxing_9.png';
        } else if (illuminated >= 55) {
            if (phase === 'WAXING') return 'moon_waxing_8.png';
            if (phase === 'WANING') return 'moon_waning_21.png';
            return 'moon_waxing_8.png';
        } else if (illuminated >= 47) {
            if (phase === 'WAXING') return 'moon_waxing_7.png';
            if (phase === 'WANING') return 'moon_waning_22.png';
            return 'moon_waxing_7.png';
        } else if (illuminated >= 43) {
            if (phase === 'WAXING') return 'moon_waxing_6.png';
            if (phase === 'WANING') return 'moon_waning_23.png';
            return 'moon_waxing_6.png';
        } else if (illuminated >= 32) {
            if (phase === 'WAXING') return 'moon_waxing_5.png';
            if (phase === 'WANING') return 'moon_waning_24.png';
            return 'moon_waxing_5.png';
        } else if (illuminated >= 23) {
            if (phase === 'WAXING') return 'moon_waxing_4.png';
            if (phase === 'WANING') return 'moon_waning_25.png';
            return 'moon_waxing_4.png';
        } else if (illuminated >= 14) {
            if (phase === 'WAXING') return 'moon_waxing_3.png';
            if (phase === 'WANING') return 'moon_waning_26.png';
            return 'moon_waxing_3.png';
        } else if (illuminated >= 8) {
            if (phase === 'WAXING') return 'moon_waxing_2.png';
            if (phase === 'WANING') return 'moon_waning_27.png';
            return 'moon_waxing_2.png';
        } else if (illuminated >= 4) {
            if (phase === 'WAXING') return 'moon_waxing_1.png';
            if (phase === 'WANING') return 'moon_waning_28.png';
            return 'moon_waxing_1.png';
        } else if (illuminated >= 1) {
            if (phase === 'WAXING') return 'moon_waxing_1.png';
            if (phase === 'WANING') return 'moon_waning_28.png';
            return 'moon_waxing_1.png';
        } else {
            return 'moon_new_29.png';
        }
    }
}
