<template>

    <div class="container page-container">

        <Spinner></Spinner>

        <h2 style="padding-top: 30px;text-align: center">Spolehlivost předpovědí oblačnosti</h2>
        <hr>

        <div class="row">
            <div class="col-lg-12">
                <CountyMap></CountyMap>
                <LocationTitle></LocationTitle>
            </div>
        </div>

        <HourlyVerifications style="margin-top: 27px"></HourlyVerifications>

        <hr class="short-hr" style="margin-top: 15px">

        <div class="text-center" style="margin-top: 25px; margin-bottom: 20px">
            <h3>Denní přehledy</h3>
        </div>

        <p style="margin-top: 27px">
            Níže je přehled hodinových předpovědí celkové oblačnosti pro vybraný den a jejich porovnání
            se skutečně naměřeným stavem. Při výběru více lokalit nebo krajů jsou hodnoty průměrovány.
        </p>

        <div class="row">
            <div class="col-lg-12">
                <VerifiedForecastTable style="margin-top: 10px;"></VerifiedForecastTable>
            </div>
        </div>

        <hr class="short-hr" style="margin-top: 15px">

        <div class="text-center" style="margin-top: 25px; margin-bottom: 20px">
            <h3>Dlouhodobá spolehlivost</h3>
        </div>

        <div class="row">
            <div class="col-lg-12">

                <div style="margin-top: 27px;">
                    <div class="row">
                        <div class="col-md-12">
                            <p>Dlouhodobé grafy podávájí agregované porovnání přesnosti předpovědí za několikadenní časový úsek ve vybraných lokalitách.
                                Pro zobrazení hodnot lze volit mezi prostou a absolutní odchylkou.
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <DiffsExplained></DiffsExplained>
                        </div>
                        <div class="col-md-4"
                             style="background-color: #f8f8f8; padding-top: 1rem">
                            <ChartsOptions></ChartsOptions>
                        </div>
                    </div>
                </div>

                <h5 class="centered" style="margin-top: 15px">Denní odchylky</h5>

                <p>Odchylky v grafu níže jsou průměrovány za kazdý den v celé České republice nebo vybraných lokalitách.</p>

                <LongTermVerificationsChart style="margin-bottom: 40px"></LongTermVerificationsChart>
            </div>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import HourlyVerifications from "@/components/verification/HourlyVerifications.vue";
import CountyMap from "@/components/verification/CountyMap.vue";
import LocationTitle from "@/components/verification/LocationTitle.vue";
import VerifiedForecastTable from "@/components/verification/VerifiedForecastTable.vue";
import ChartsOptions from "@/components/verification/ChartsOptions.vue";
import DiffsExplained from "@/components/verification/DiffsExplained.vue";
import LongTermVerificationsChart from "@/components/verification/LongTermVerificationsChart.vue";
import Spinner from "@/components/reusables/Spinner.vue";

export default {
    name: "VerifiedForecasts",
    created() {
        if (!this.getForecastDate) {
            this.loadDataForWholeCountry();
        }
    },
    mounted () {
        window.scrollTo(0, 0);
        this.dataRefreshInterval = setInterval(() => {
            if (new Date().getMinutes() === 17) {
                console.log("Scheduled verified forecast refresh ...")
                this.refreshData();
            }
        }, 60 * 1000);
    },
    beforeDestroy() {
        console.log("VerifiedForecasts: beforeDestroy")
        if (this.dataRefreshInterval) {
            clearInterval(this.dataRefreshInterval);
        }
    },
    data() {
        return {
            dataRefreshInterval: null,
            dataRefreshTimerId: null
        }
    },
    computed: {
        ...mapGetters('verificationsStore', ['getPageTitle', 'getForecastDate'])
    },
    methods: {
        ...mapActions('verificationsStore', ['loadDataForWholeCountry', 'refreshData']),
    },
    components: {
        Spinner,
        LongTermVerificationsChart,
        DiffsExplained,
        ChartsOptions,
        HourlyVerifications, CountyMap, VerifiedForecastTable, LocationTitle}
}

</script>
