<template>
    <div>
        <h6 style="margin-top: 40px; text-align: center; font-size: 22px; font-weight: 600;">
            Jak se mění předpovědi na noc
        </h6>

        <div class="row">
            <div class="col-sm-6">
                <ForecastTrendChart></ForecastTrendChart>
            </div>
            <div class="col-sm-6">
                <ForecastTrendDescription style="margin-top: 20px"></ForecastTrendDescription>
                <Tip text="Můžete si určit, jaká část noci vás zajímá."></Tip>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ForecastTrendChart from "@/components/forecast/ForecastTrendChart";
import {Utils} from "@/utils/Utils";
import ForecastTrendDescription from "@/components/forecast/ForecastTrendDescription";
import Tip from "@/components/Tip.vue";

export default {
    name: "ForecastTrend",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastTrend', 'getPageTitle']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
            trendPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', []),
        getStyle() {
            const trend = this.getForecastTrend.trend;
            if (trend === 'WORSE') {
                return "color: rgb(236 89 89); background-color: rgb(255 233 233)";
            } else if (trend === 'UNSTABLE') {
                    return "color: rgb(253 154 27); background-color: rgb(255 233 233)";
            } else if (trend === 'BETTER') {
                return "color: #4ba74b; background-color: rgb(233 247 218)";
            } else if (trend === 'STABLE') {
                return "color: grey; background-color: #f1eeee";
            }
        },
        toggleTrendPopup() {
            this.trendPopupDisplayed = !this.trendPopupDisplayed;
        },
        getDate() {
            return Utils.formatDate(new Date(this.getForecastTrend.date), true, false, true);
        }
    },
    components: {
        Tip,
        ForecastTrendDescription, ForecastTrendChart
    }
}
</script>

<style scoped>


</style>
