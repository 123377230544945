
export let narrowViewPortMixin = {

    beforeMount() {
        this.toggle(); // call when first loaded
        window.addEventListener('resize',  this.toggle);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.toggle); // super important ...
    },

    data() {
        return {
            isNarrowViewPort: this.isNarrow()
        }
    },

    methods: {
        toggle: function () {
            if (this.isNarrow()) {
                this.isNarrowViewPort = true;
            } else {
                this.isNarrowViewPort = false;
            }
        },
        isNarrow() {
            return window.innerWidth <= 950; // TODO put into some config ...
        }

    }

}
