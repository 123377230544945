import {ApiClient} from "@/api/calculations/api-client";
import {Locations} from "@/config/locations";

// noinspection JSNonASCIINames
const state = {
    loadingVerifications: false,
    loadingVerifiedForecasts: false,
    lastRefresh: null, // TODO use when e.g. browser tab becomes active
    selectedCounties: [],
    mouseOverCounty: null,
    selectedLocations: [],
    mouseOverLocation: null,
    pageTitle: null,
    pastHours: [],
    dailyChart: null,
    displayedDailyChartDiffType: 'diff-abs',
    displayedForecastDiffType: 'diff',
    forecasts: null,
    daysOffsetFromToday: 0,
    showUpdatedTime: false,
    activeStations: []
}


const getters = {

    isLoadingVerifications: (state) => {
        return state.loadingVerifications;
    },

    isLoadingVerifiedForecasts: (state) => {
        return state.loadingVerifiedForecasts;
    },

    getPageTitle: (state) => {
        return state.pageTitle;
    },

    pastHoursExist: (state) => {
        return state.pastHours && state.pastHours.length > 0;
    },

    pastHoursExistFor: (state) => (hours) => {
        // console.log(hours)
        if (state.pastHours) {
            let ph = state.pastHours.filter(ph => ph.pastHours === hours);
            if (ph[0].verifications.length > 0) {
                // console.log("returning true ...")
                return true;
            }
        }
        // console.log("returning false ...")
        return false;
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getPastHours: (state, getters, rootState, rootGetters) => (hours) => {
        // console.dir(rootState);
        const displayedSources = rootGetters["settingsStore/getDisplayedSources"];
        const hourVerifications = state.pastHours.filter(ph => ph.pastHours === hours)[0];
        return hourVerifications.verifications.filter(v => displayedSources.includes(v.source));
    },

    getSourceForecasts: (state) => {
        if (state.forecasts) {
            return state.forecasts.sourceForecasts;
        }
        return [];
    },

    getForecast: (state) => (sourceCode) => {
        if (state.forecasts) {
            // console.log(sourceCode);
            const found = state.forecasts.sourceForecasts.filter(f => f.source === sourceCode);
            if (found.length === 1) {
                // console.log(found[0]);
                return found[0];
            }
        }
        return null;
    },

    getDayMeasurements: (state) => {
        if (state.forecasts) {
            return state.forecasts.dayMeasurements.measurements;
        }
        return [];
    },

    getForecastDate: (state) => {
        if (state.forecasts) {
            return state.forecasts.date;
        }
        return null;
    },

    previousDayExists: (state) => {
        if (state.forecasts) {
            return state.forecasts.previousDayExists;
        }
        return null;
    },

    nextDayExists: (state) => {
        if (state.forecasts) {
            return state.forecasts.nextDayExists;
        }
        return null;
    },

    getDaysOffsetFromToday: (state) => {
        return state.daysOffsetFromToday;
    },

    todayForecastDisplayed: (state) => {
        return state.daysOffsetFromToday === 0;
    },

    getDailyChart: (state) => {
        return state.dailyChart;
    },

    getSelectedCounties: (state) => {
        return state.selectedCounties;
    },

    getSelectedLocations: (state) => {
        return state.selectedLocations;
    },

    getCountyFill: (state) => (countyId) => {
        // console.log("checking fill ...")
        const county = Locations.getCountyById(countyId);
        if (!county) {
            console.error("Failed to find county for id = " + countyId)
        }
        if (state.selectedCounties.includes(county)) {
            // console.log("inside filling ....>>>>")
            return "fill: #000078";
        } else if (state.mouseOverCounty === county) {
            return "fill: #2790E3";
        } else {
            return "";
        }
    },

    getMapLocationFill: (state) => (location) => {
        // console.log("checking location fill ...")
        const width = window.innerWidth;
        var incr = 0;
        if (width < 1024) {
            incr = 2;
        }

        const active = state.activeStations.includes(location);
        const activeColor = "#8CFF67";
        const inactiveColor = "#ffd877";
        const color = active ? activeColor : inactiveColor;

        if (state.selectedLocations.includes(location)) {
            return "fill: " + color + "; r: " + (8 + incr);
        } else if (state.mouseOverLocation === location) {
            return "fill: + color +; r: " + (6 + incr);
        } else {
            return "fill: " + color + "; r: " + (5 + incr);
        }
    },

    getDisplayedDailyChartDiffType: (state) => {
        return state.displayedDailyChartDiffType;
    },

    getDisplayedForecastDiffType: (state) => {
        return state.displayedForecastDiffType;
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getDailyValues: (state, rootGetters, rootState) => (source) => {
        if (state.dailyChart) {
            const displayedSources = rootState.settingsStore.displayedSources;
            // console.log("displayedSources: " + displayedSources)
            let sourceSeries = state.dailyChart.series.filter(s => s.source === source && displayedSources.includes(source));
            if (sourceSeries && sourceSeries.length > 0 && sourceSeries[0].values) {
                const valueChooser = val => state.displayedDailyChartDiffType === 'diff' ? val.avgDiff : val.avgDiffAbs;
                return sourceSeries[0].values.map(v => ({
                    x: v.date,
                        y: valueChooser(v)
                }));
            }
        }
        return [];
    },

    getShowUpdateTime: (state) => {
        return state.showUpdatedTime;
    }

}


const mutations = {

    setLoadingVerifications: (state, value) => {
        state.loadingVerifications = value;
    },

    setLoadingVerifiedForecasts: (state, value) => {
        state.loadingVerifiedForecasts = value;
    },

    setPageTitle: (state, value) => {
        state.pageTitle = value;
    },

    setPastHours: (state, newValues) => {
        state.pastHours.splice(0, state.pastHours.length);
        state.pastHours.push(...newValues);
    },

    setActiveStations: (state, value) => {
        if (value) {
            state.activeStations = value;
        } else {
            state.activeStations = [];
        }
    },

    setForecasts: (state, value) => {
        state.forecasts = value;
    },

    incrDaysOffsetFromToday: (state, increment) => {
        state.daysOffsetFromToday = state.daysOffsetFromToday + increment;
    },

    setDailyChart: (state, value) => {
        state.dailyChart = value;
    },

    setPastHoursCounty: (state, newValues) => {
        state.pastHoursCounty = newValues;
    },

    setDailyChartCounty: (state, value) => {
        state.dailyChartCounty = value;
    },

    unselectCounty: (state, county) => {
        const index = state.selectedCounties.indexOf(county);
        if (index > -1) {
            state.selectedCounties.splice(index, 1);
        }
    },

    unselectAllCounties: (state) => {
        state.selectedCounties.splice(0, state.selectedCounties.length);
    },

    unselectLocation: (state, location) => {
        const index = state.selectedLocations.indexOf(location);
        if (index > -1) {
            state.selectedLocations.splice(index, 1);
        } else {
            console.warn("Failed to find location to unselect: " + location);
        }
    },

    unselectAllLocations: (state) => {
        state.selectedLocations.splice(0, state.selectedLocations.length);
    },

    addSelectedCounty: (state, county) => {
        state.selectedCounties.push(county);
    },

    addSelectedLocation: (state, location) => {
        const index = state.selectedLocations.indexOf(location);
        if (index === -1) { // only if not already listed
            state.selectedLocations.push(location);
        }
    },

    setSelectedLocations: (state, locations) => {
        if (locations) {
            const locs = state.selectedLocations;
            locs.splice(0, locs.length); // remove all
            for (const l of locations) {
                locs.push(l);
            }
        }
    },

    setDisplayedDailyChartDiffType: (state, value) => {
        state.displayedDailyChartDiffType = value;
    },

    setDisplayedForecastDiffType: (state, value) => {
        state.displayedForecastDiffType = value;
    },

    setMouseOverCounty: (state, county) => {
        state.mouseOverCounty = county;
    },

    setMouseOverLocation: (state, location) => {
        state.mouseOverLocation = location;
    },

    setShowUpdateTime: (state, value) => {
        state.showUpdatedTime = value;
    }

}


const actions = {

    refreshData({getters, dispatch}) {
        console.log("Refreshing ...")
        if (getters.getSelectedLocations.length === 0) {
            dispatch('loadDataForWholeCountry');
        } else {
            dispatch('loadDataForLocations');
        }
    },

    loadDataForWholeCountry({commit, getters}) {
        commit('setLoadingVerifications', true)
        commit('setLoadingVerifiedForecasts', true)
        ApiClient.fetchVerifications(null)
            .then(verifications => {
                    if (verifications) {
                        console.log("Received verifications: " + verifications)
                        commit('setPastHours', verifications.pastHours);
                        commit('setDailyChart', verifications.dailyChart);
                        commit('setPageTitle', verifications.title);
                    } else {
                        console.log("Failed to receive home page")
                    }
                    commit('setLoadingVerifications', false);
                }
            );
        ApiClient.fetchVerifiedForecasts(null, getters.getDaysOffsetFromToday)
            .then(forecasts => {
                    if (forecasts) {
                        console.log("Received verified forecasts: " + forecasts)
                        commit('setForecasts', forecasts);
                        if (forecasts.activeStations) {
                            commit('setActiveStations', forecasts.activeStations.names);
                        }
                    } else {
                        console.log("Failed to receive verifications")
                    }
                    commit('setLoadingVerifiedForecasts', false);
                }
            );
    },

    loadDataForLocations({commit, getters}) {
        console.log("will load data for locations ...")
        commit('setLoadingVerifications', true);
        commit('setLoadingVerifiedForecasts', true)
        ApiClient.fetchVerifications(getters.getSelectedLocations)
            .then(homePage => {
                    if (homePage) {
                        console.log("Received verifications: " + homePage)
                        commit('setPastHours', homePage.pastHours);
                        commit('setDailyChart', homePage.dailyChart);
                        commit('setPageTitle', homePage.title);
                    } else {
                        console.log("Failed to receive home page");
                    }
                    commit('setLoadingVerifications', false);
                }
        );
        ApiClient.fetchVerifiedForecasts(getters.getSelectedLocations, getters.getDaysOffsetFromToday)
            .then(forecasts => {
                    if (forecasts) {
                        console.log("Received forecasts: " + forecasts)
                        commit('setForecasts', forecasts);
                    } else {
                        console.log("Failed to receive forecasts")
                    }
                    commit('setLoadingVerifiedForecasts', false);
                }
            );
    },

    unselectCounty({commit}, county) {
        commit('unselectCounty', county);
        let locations = Locations.getLocationsByCounty(county);
        console.log("Will unselect: " + locations);
        if (locations) {
            locations.forEach(location =>
                commit('unselectLocation', location)
            )
        }
    },

    unselectAllCounties({commit}) {
        commit('unselectAllCounties');
    },

    unselectLocation({commit, dispatch}, location) {
        commit('unselectLocation', location);
        dispatch('checkAndUnselectCounties');
    },

    checkAndUnselectCounties({commit, state}) {
        if (state.selectedLocations.length === 0) {
            commit('unselectAllCounties');
        } else {
            for (const county of state.selectedCounties) {
                if (!Locations.locationsAreCompleteForCounty(county, state.selectedLocations)) {
                    commit('unselectCounty', county);
                }
            }
        }
    },

    unselectAllLocations({commit, dispatch}) {
        commit('unselectAllLocations');
        dispatch('unselectAllCounties');
        dispatch('refreshData');
    },

    addSelectedCounty({commit}, county) {
        commit('addSelectedCounty', county);
        let locations = Locations.getLocationsByCounty(county);
        if (locations) {
            locations.forEach(location =>
                commit('addSelectedLocation', location)
            )
        }
    },

    addSelectedLocation({commit}, location) {
        commit('addSelectedLocation', location);
        let county = Locations.getCountyByLocation(location);
        let wholeCountySelected = Locations.locationsAreCompleteForCounty(county, state.selectedLocations);
        if (wholeCountySelected) {
            commit('addSelectedCounty', county);
        }
    },

    toggleDisplayedDailyChartDiffType: ({commit}, option) => {
        commit('setDisplayedDailyChartDiffType', option);
    },

    toggleDisplayedForecastDiffType: ({commit}, option) => {
        commit('setDisplayedForecastDiffType', option);
    },

    changeMouseOverCounty: ({commit}, county) => {
        commit('setMouseOverCounty', county);
    },

    changeMouseOverLocation: ({commit}, location) => {
        commit('setMouseOverLocation', location);
    },

    toPreviousDayForecast({dispatch}) {
        dispatch('moveForecastDate', -1);
    },

    toNextDayForecast({dispatch}) {
        dispatch('moveForecastDate', 1);
    },

    toTodaysForecast({getters, dispatch}) {
        let step = - getters.getDaysOffsetFromToday;
        dispatch('moveForecastDate', step);
    },

    moveForecastDate({commit, getters, dispatch}, days) {
        if ((days > 0 && getters.nextDayExists)
            || (days < 0 && getters.previousDayExists)) {
            commit('incrDaysOffsetFromToday', days);
            if (getters.getSelectedLocations.length === 0) {
                dispatch('loadDataForWholeCountry');
            } else {
                dispatch('loadDataForLocations');
            }
        }
    },

    toggleShowUpdateTime: ({commit}, value) => {
        commit('setShowUpdateTime', value);
    },


}


export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}
