<template>

    <div class="container" style="max-width: 900px; padding-left: 40px; padding-right: 40px; ; padding-bottom: 40px; background-color: white; margin-top: 95px;">

        <h2 style="padding-top: 30px;text-align: center">Cookies</h2>
        <hr>

        <p>Projekt nevyužívá cookies a nesbírá žádné osobní údaje.</p>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "Cookies",
    computed: {
        ...mapGetters([])
    },
    methods: {
    },
    components: {}
}

</script>
