<template>
    <div>
        <div class="row justify-content-center"
        style="margin-bottom: 10px">
            <b-form-radio-group buttons button-variant="outline-primary" size="sm" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="diff-abs">
                    Absolutní odchylky
                </b-form-radio>
                <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="diff">
                    Prosté odchylky
                </b-form-radio>
            </b-form-radio-group>
        </div>
        <div class="row">
            <p v-if="selected==='diff'">
                <b>Kladná prostá odchyka</b> znamená, že je model průměrně optimističtější, než byl reálný stav oblačnosti.
                <b>Záporná odchylka</b> poukazuje na pesimistický model.
            </p>
            <p v-else>
                <b>Absolutní odchyka</b> vystihuje, jak se předpovědi průměrně mýlily, bez ohledu na to,
                jestli byly příliš optmistické nebo pesimistické. Denní odchylka se počítá z dílčích absolutních hodnot
                odchylek a díky tomu se reálná chyba neschová tím, že by se pozitivní odchylka z jedné lokality vykompenzovala
                negativní z jiné měřené lokality (jako tomu může být u prosté odchylky).
            </p>
        </div>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";
import {BFormRadioGroup} from 'bootstrap-vue';
import {BFormRadio} from 'bootstrap-vue';

export default {
    name: "ForecastLongTermVerificationsChartOptions",
    computed: {
        ...mapGetters('forecastsStore', ['getDisplayedDailyChartDiffType']),
    },
    watch: {
        selected: function () {
            // console.log("selected: " + this.selected)
            this.toggleDisplayedDailyChartDiffType(this.selected);
        }
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['toggleDisplayedDailyChartDiffType']),
    },
    mounted() {
        this.selected = this.getDisplayedDailyChartDiffType;
    },
    components: {
        BFormRadioGroup, BFormRadio
    }
}
</script>

<style>
</style>
